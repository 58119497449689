import React from 'react'
import home_products from "../../assets/images/home-products.png"
// import './style.css'
import amazon from '../../assets/images/amazon 1.png'
import Framesharp from '../../assets/images/Frame 1171280881 1.png'
import kitchenaid from '../../assets/images/kitchen-aid 1.png'
import rugsusa from '../../assets/images/rugsusa 1.png'
import Layout from '../Layout'

function  Home_products() {
  return (
    <Layout>
    <section className='home_products'>
        <div className='container'>
        <div className='text-center'>
      <h2>Become An Architectural Designs Affiliate </h2>
        <img src={home_products} />
      </div>
      <div className='furnishing'>
          <h5>Home Decor and Furnishings</h5>
          <div className='logo-decor'>
              <img src={amazon} />
              <img src={Framesharp} />
              <img src={kitchenaid} />
              <img src={rugsusa} />
          </div>
      </div>
        </div>
    </section>
    </Layout>
  )
}

export default Home_products