// import React, { useState } from 'react';
// import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
// import "pure-react-carousel/dist/react-carousel.es.css";

// const ImageModal = ({ showModal, onClose, images }) => {
//     const [currentImageIndex, setCurrentImageIndex] = useState(0);

//     const handleNext = () => {
//         setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
//     };

//     const handlePrev = () => {
//         setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
//     };

//     if (!showModal) return null;

//     return (
//         <div className="modal-overlay" onClick={onClose}>
//             <div className="modal-content" onClick={(e) => e.stopPropagation()}>
//                 <CarouselProvider
//                     currentSlide={currentImageIndex}
//                     totalSlides={images.length}
//                     naturalSlideWidth={100}
//                     naturalSlideHeight={125}
//                     isPlaying={false}
//                 >
//                     <Slider>
//                         {images.map((image, index) => (
//                             <Slide key={index} index={index}>
//                                 <img src={image} alt={`Property ${index}`} style={{ width: '100%', height: 'auto' }} />
//                             </Slide>
//                         ))}
//                     </Slider>
//                     <button className="prev-btn" onClick={handlePrev}>&lt;</button>
// <button className="next-btn" onClick={handleNext}>&gt;</button>

//                 </CarouselProvider>
//                 <button className="close-btn" onClick={onClose}>X</button>
//             </div>
//         </div>
//     );
// };

// export default ImageModal;


import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Ensure Bootstrap Icons are imported

const ImageModal = ({ showModal, onClose, images, imageId }) => {
    const [currentIndex, setCurrentIndex] = useState(0);



    useEffect(() => {
        // If imageId is provided, find the corresponding index and show that image
        if (imageId != null && images.length > 0) {
            const index = images.findIndex((img) => img.id === imageId); // Assuming each image has an 'id' field
            if (index !== -1) {
                setCurrentIndex(index);
            }
        }
    }, [imageId, images]);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length); // Cycle through images
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length); // Cycle through images
    };

    if (!showModal) return null; // Do not render the modal if `showModal` is false

    return (
        <div className="image-modal">
            <div className="modal-content-image-full">
                <button className="close-btn" onClick={onClose}>
                <i className="bi bi-x-lg"></i> {/* Close button with Bootstrap icon */}
                </button>

                <div className="modal-image-container">
                    <img src={images[currentIndex]} alt={`Image ${currentIndex + 1}`} className="modal-image-image-full" />
                </div>

                {/* Navigation Icons */}
                <button className="prev-btn" onClick={handlePrev}>
                    <i className="bi bi-chevron-left"></i> {/* Left chevron icon */}
                </button>
                <button className="next-btn" onClick={handleNext}>
                    <i className="bi bi-chevron-right"></i> {/* Right chevron icon */}
                </button>
            </div>
        </div>
    );
};

// Prop types for the ImageModal component
ImageModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    images: PropTypes.array.isRequired,
};

export default ImageModal;

