// import React from 'react'
// import Layout from './Layout'
// import aboutimg from '../assets/images/about-img.png'
// import btnarrow from "../assets/images/btn-arrow.svg";

// const AboutUs = () => {
//   return (
//     <>
//       <Layout>
//         <section className='ourstory'>
//           <div className='container'>
//             <h2>Our Story & Team</h2>
//             <div className='row'>
//               <div className='col-lg-8'>
//                 <div className='content-wrap'>
//                   <h4>We have been selling quality pre-drawn house plans (and garage plans too!) for generations.</h4>
//                   <p>Architectural Designs offers house plans from over 200 architects and designers. Our family-owned business has been selling home plans to builders and homeowners across North America (as well as some internationally) for generations. Our designs include everything from starter homes to elaborate luxury homes. We even sell garage plans and multi-family homes. Our home plans are sold as-is or can be modified to suit your custom needs.Our content is unrivaled. We add new home designs and client photos daily to our portfolio of over 30,000 plans making us the go-to source for relevant house plans. Architectural Designs is based in Connecticut, our seasoned staff with unmatched expertise is here to help you find the perfect house plan that matches your needs and budget.
//                     <br></br>
//                   </p>
//                   <span><b>Ready when you are. What plan do YOU want to build?</b></span>
//                   <div className="btm-butn">
//                     <button type="button" class="btn btn-primary mt-5">
//                       Browse Properties
//                       <img src={btnarrow} />
//                     </button>
//                   </div>
//                 </div>
//               </div>
//               <div className='col-lg-4'>
//                 <div className='img-wrap'>
//                   <img src={aboutimg} />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//         <section className='team-members'>
//           <div className="top-heading">
//             <p className="mb-0">Our Team Members</p>
//             <h4>Explore Team members</h4>
//           </div>
//           <div className='container'>
//             <div className='row'>
//               <div className='col-lg-4'>
//                 <div className='team-wrap'>
//                   <div className='team-img'>
//                     <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
//                       <circle cx="50.5" cy="50.5" r="50" fill="#D9D9D9" />
//                     </svg>
//                     <strong>John Doe</strong>
//                   </div>
//                   <h6>CHIEF EXECUTIVE OFFICER</h6>
//                   <p>Jon, owner of Architectural Designs is the glue that keeps it all running. His positive attitude, passion about his family-run business and customer-centric focus has created a magical workplace for his team. Jon </p>
//                 </div>
//               </div>
//               <div className='col-lg-4'>
//                 <div className='team-wrap'>
//                   <div className='team-img'>
//                     <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
//                       <circle cx="50.5" cy="50.5" r="50" fill="#D9D9D9" />
//                     </svg>
//                     <strong>John Doe</strong>
//                   </div>
//                   <h6>CHIEF EXECUTIVE OFFICER</h6>
//                   <p>Jon, owner of Architectural Designs is the glue that keeps it all running. His positive attitude, passion about his family-run business and customer-centric focus has created a magical workplace for his team. Jon </p>
//                 </div>
//               </div>
//               <div className='col-lg-4'>
//                 <div className='team-wrap'>
//                   <div className='team-img'>
//                     <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
//                       <circle cx="50.5" cy="50.5" r="50" fill="#D9D9D9" />
//                     </svg>
//                     <strong>John Doe</strong>
//                   </div>
//                   <h6>CHIEF EXECUTIVE OFFICER</h6>
//                   <p>Jon, owner of Architectural Designs is the glue that keeps it all running. His positive attitude, passion about his family-run business and customer-centric focus has created a magical workplace for his team. Jon </p>
//                 </div>
//               </div>
//               <div className='col-lg-4'>
//                 <div className='team-wrap'>
//                   <div className='team-img'>
//                     <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
//                       <circle cx="50.5" cy="50.5" r="50" fill="#D9D9D9" />
//                     </svg>
//                     <strong>John Doe</strong>
//                   </div>
//                   <h6>CHIEF EXECUTIVE OFFICER</h6>
//                   <p>Jon, owner of Architectural Designs is the glue that keeps it all running. His positive attitude, passion about his family-run business and customer-centric focus has created a magical workplace for his team. Jon </p>
//                 </div>
//               </div>
//               <div className='col-lg-4'>
//                 <div className='team-wrap'>
//                   <div className='team-img'>
//                     <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
//                       <circle cx="50.5" cy="50.5" r="50" fill="#D9D9D9" />
//                     </svg>
//                     <strong>John Doe</strong>
//                   </div>
//                   <h6>CHIEF EXECUTIVE OFFICER</h6>
//                   <p>Jon, owner of Architectural Designs is the glue that keeps it all running. His positive attitude, passion about his family-run business and customer-centric focus has created a magical workplace for his team. Jon </p>
//                 </div>
//               </div>
//               <div className='col-lg-4'>
//                 <div className='team-wrap'>
//                   <div className='team-img'>
//                     <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
//                       <circle cx="50.5" cy="50.5" r="50" fill="#D9D9D9" />
//                     </svg>
//                     <strong>John Doe</strong>
//                   </div>
//                   <h6>CHIEF EXECUTIVE OFFICER</h6>
//                   <p>Jon, owner of Architectural Designs is the glue that keeps it all running. His positive attitude, passion about his family-run business and customer-centric focus has created a magical workplace for his team. Jon </p>
//                 </div>
//               </div>
//             </div>
//             <div className="all-location">
//               <button type="button" class="btn btn-outline-primary phno">
//                 {" "}
//                 View All Members{" "}
//                 <svg
//                   width="22"
//                   height="22"
//                   viewBox="0 0 22 22"
//                   fill="none"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <mask
//                     id="mask0_504_23861"
//                     maskUnits="userSpaceOnUse"
//                     x="0"
//                     y="0"
//                     width="22"
//                     height="22"
//                   >
//                     <rect width="22" height="22" fill="#D9D9D9" />
//                   </mask>
//                   <g mask="url(#mask0_504_23861)">
//                     <path
//                       d="M11.8671 11L7.65039 6.78332L8.61633 5.81738L13.7989 11L8.61633 16.1826L7.65039 15.2167L11.8671 11Z"
//                       fill="#F46530"
//                     />
//                   </g>
//                 </svg>
//               </button>
//             </div>
//           </div>
//         </section>
//       </Layout>
//     </>
//   )
// }

// export default AboutUs




import React from 'react'
import Layout from './Layout'
import aboutimg from '../assets/images/about-img.png'
import btnarrow from "../assets/images/btn-arrow.svg";
import { Link } from "react-router-dom";
const AboutUs = () => {
  return (
    <>
      <Layout>
        <section className='ourstory'>
          <div className='container'>
            <h2>Our Story & Team</h2>
            <div className='row'>
              <div className='col-lg-8'>
                <div className='content-wrap'>
                  <h4>At Rholzsquad LLC,</h4>
                  <p>our journey began with a shared passion for design excellence and a commitment to delivering high-quality residential permit drawings. Over the years, our leadership team has garnered vast experience in architectural and engineering design, having over 200 architects and designers, collaborating with industry-leading firms and tackling diverse projects worldwide.  
                    <br></br>
                  </p>
                  <p>Guiding our structural integrity reviews is <b>S.A. Funminiyi,</b> a highly skilled Professional Engineer (P.E.), whose expertise ensures every design aligns with safety and regulatory requirements. Their meticulous attention to detail guarantees that our clients receive permit-ready designs they can trust.    
                    <br></br>
                  </p> 
                    <p>Today, our partnership with S3M DEVELOPERS in Florida strengthens our ability to deliver exceptional designs, leveraging cutting-edge tools and industry insights. From concept to completion, our team remains committed to providing designs that simplify the permitting process and empower homeowners, contractors, and developers to bring their visions to life. 
                    <br></br>
                  </p>
                  <p>Together, we’re building a future where permit designs are accessible, efficient, and of the highest quality. Welcome to Rholzsquad LLC—where your design needs meet our dedication to excellence.
                    <br></br>
                  </p>
                  {/* <span><b>Ready when you are. What plan do YOU want to build?</b></span> */}
                  <Link to='/propertyList' className="btm-butn">
                    <button type="button" class="btn btn-primary mt-5">
                      Browse Properties
                      <img src={btnarrow} />
                    </button>
                  </Link>
                </div>
              </div>
              <div className='col-lg-4'>
                <div className='img-wrap'>
                  <img src={aboutimg} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='team-members'>
          <div className="top-heading">
            <p className="mb-0">Our Team Members</p>
            <h4>Explore Team members</h4>
          </div>
          <div className='container'>
            <div className='row  d-flex justify-content-center '>
              <div className='col-lg-4 '>
                <div className='team-wrap'>
                  <div className='team-img'>
                    <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="50.5" cy="50.5" r="50" fill="#D9D9D9" />
                    </svg>
                    <strong>W.S. Francis</strong>
                  </div>
                  <h6>CHIEF EXECUTIVE OFFICER</h6>
                  <p>Our CEO and Design Architect, <b>W.S. Francis,</b> brings over 15 years of professional expertise in crafting architectural and engineering solutions. With a history as a Design Engineer at ISLAND DESIGNERS in Australia and a Team Lead at S3M DEVELOPERS in Florida, Francis combines technical precision with creative ingenuity to ensure every design meets both aesthetic and functional standards.  </p>
                </div>
              </div>
              {/* <div className='col-lg-4'>
                <div className='team-wrap'>
                  <div className='team-img'>
                    <svg width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="50.5" cy="50.5" r="50" fill="#D9D9D9" />
                    </svg>
                    <strong>John Doe</strong>
                  </div>
                  <h6>CHIEF EXECUTIVE OFFICER</h6>
                  <p>Jon, owner of Architectural Designs is the glue that keeps it all running. His positive attitude, passion about his family-run business and customer-centric focus has created a magical workplace for his team. Jon </p>
                </div>
              </div> */}
             
            </div>
            <div className="all-location">
              <button type="button" class="btn btn-outline-primary phno">
                {" "}
                View All Members{" "}
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_504_23861"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="22"
                    height="22"
                  >
                    <rect width="22" height="22" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_504_23861)">
                    <path
                      d="M11.8671 11L7.65039 6.78332L8.61633 5.81738L13.7989 11L8.61633 16.1826L7.65039 15.2167L11.8671 11Z"
                      fill="#F46530"
                    />
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default AboutUs
