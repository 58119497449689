// src/redux/contactUsSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import Api from '../../service/Api';
import { toast } from 'react-toastify';
// Async thunk action to handle API call
export const submitContactForm = createAsyncThunk(
  'contactUs/submitContactForm',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await Api.post('/contact-us', formData);
   
      return response.data;

    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const contactUsSlice = createSlice({
  name: 'contactUs',
  initialState: {
    loading: false,
    success: false,
    error: null,
  },
  reducers: {
    resetContactForm: (state) => {
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitContactForm.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(submitContactForm.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(submitContactForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetContactForm } = contactUsSlice.actions;

export default contactUsSlice.reducer;
