const constructQueryString = (obj) => {
    if (obj) {
        const queryString = Object.keys(obj)
            .filter(key => obj[key] !== "" && obj[key] !== null && obj[key] !== undefined)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
            .join('&');
        return queryString;
    } else {
        return ""
    }
}

export {
    constructQueryString
}

// const constructQueryString = (obj) => {
//     if (obj) {
//         const queryString = Object.keys(obj)
//             .filter(key => obj[key] !== "" && obj[key] !== null && obj[key] !== undefined)
//             .map(key => {
//                 // Handle arrays differently by concatenating values into the same key
//                 if (Array.isArray(obj[key])) {
//                     return obj[key].map(val => `${encodeURIComponent(key)}[]=${encodeURIComponent(val)}`).join('&');
//                 } else {
//                     return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
//                 }
//             })
//             .join('&');
//         return queryString;
//     } else {
//         return "";
//     }
// }

// export {
//         constructQueryString
//     }