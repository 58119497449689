// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import axios from 'axios';
// import Api from '../../service/Api';

// // Async action to fetch favorites from the API
// export const getFavourites = createAsyncThunk('favourites/getFavourites', async (_, thunkAPI) => {
//   try {
//     const response = await Api.get('/favorite'); // Update with the actual API endpoint
//     return response.data;
//   } catch (error) {
//     return thunkAPI.rejectWithValue(error.response.data);
//   }
// });

// const favouritesSlice = createSlice({
//   name: 'favourites',
//   initialState: {
//     favouritesList: [],
//     loading: false,
//     error: null,
//   },
//   reducers: {},
//   extraReducers: (builder) => {
//     builder
//       .addCase(getFavourites.pending, (state) => {
//         state.loading = true;
//         state.error = null;
//       })
//       .addCase(getFavourites.fulfilled, (state, action) => {
//         state.loading = false;
//         state.favouritesList = action.payload;
//       })
//       .addCase(getFavourites.rejected, (state, action) => {
//         state.loading = false;
//         state.error = action.payload || 'Something went wrong';
//       });
//   },
// });

// export default favouritesSlice.reducer;


import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Api from '../../service/Api';
import { toast } from "react-toastify";

// Async action to fetch favorites from the API
export const getFavourites = createAsyncThunk('favourites/getFavourites', async (_, thunkAPI) => {
  try {
    const response = await Api.get('/favorite'); // Update with the actual API endpoint
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

// Async action to add a property to favorites
export const addToFavourites = createAsyncThunk(
  'favourites/addToFavourites',
  async (propertyId, thunkAPI) => {
    try {
      const response = await Api.post('/favorite', { property_id: propertyId }); // Update with the actual API endpoint
      toast.success(response?.data?.message);
      if(response.data.status===401){
        toast.warning("please login to perform this action ")
      }
      return response?.data; // Return the added favourite property data

    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const favouritesSlice = createSlice({
  name: 'favourites',
  initialState: {
    favouritesList: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get favourites
    builder
      .addCase(getFavourites.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFavourites.fulfilled, (state, action) => {
        state.loading = false;
        state.favouritesList = action.payload;
      })
      .addCase(getFavourites.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Something went wrong';
      })
      // Add to favourites
      .addCase(addToFavourites.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addToFavourites.fulfilled, (state, action) => {
        state.loading = false;
        // Optionally, you could update the favouritesList with the new favourite property
        // state?.favouritesList?.push(action.payload);
      })
      .addCase(addToFavourites.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || 'Failed to add to favourites';
      });
  },
});

export default favouritesSlice.reducer;
