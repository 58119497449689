// src/components/Spinner.js
import React from 'react';
import PropTypes from 'prop-types';

const Spinner = ({ color = 'primary', size = 'md', fullPage = true }) => {
    const sizeClass = size === 'sm' ? 'spinner-border-sm' : ''; // Optional small size spinner
    const spinnerStyle = fullPage ? { height: '100vh' } : {};

    return (
        <div className="d-flex justify-content-center align-items-center" style={spinnerStyle}>
            <div className={`spinner-border text-${color} ${sizeClass}`} role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
};

// Prop types for validation
Spinner.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md']),
    fullPage: PropTypes.bool,
};

export default Spinner;
