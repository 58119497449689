import React from 'react'
import Layout from './Layout'
import blogimg from "../assets/images/blogimg.png"
import Pagination from 'react-bootstrap/Pagination';
import { Link } from 'react-router-dom'

const Blog = () => {
  return (
    <>
      <Layout>
        <div className='m-5 mobil'>
        <section className='blog'>
       <div className='container'>
           <h2>Blogs</h2>
           <div className='row'>
               <div className='col-lg-4'>
                    <div className='blog-cover'>
                       <div>
                       <img src={blogimg} />
                     <div className='bg-content'>
                            <div className='dte'><p>Alec Whitten <span>• 17 Jan 2022</span></p></div>
                     
                        <strong>Bill Walsh leadership lessons</strong>
                        <p>Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Like to know ......</p>
                    <div className='read-more-blg'>
                        <Link to="/blogdetails">Read More <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.998535 8.00037L15.9692 7.99936" stroke="#F46530" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.88672 1.91864L15.9681 8.00006L9.8859 14.0823" stroke="#F46530" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></Link>
                    </div>
                     </div>
                    </div>
                    </div>
               </div>
               <div className='col-lg-4'>
                    <div className='blog-cover'>
                       <div>
                       <img src={blogimg} />
                     <div className='bg-content'>
                            <div className='dte'><p>Alec Whitten <span>• 17 Jan 2022</span></p></div>
                     
                        <strong>Bill Walsh leadership lessons</strong>
                        <p>Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Like to know ......</p>
                    <div className='read-more-blg'>
                        <Link to="/blogdetails">Read More <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.998535 8.00037L15.9692 7.99936" stroke="#F46530" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.88672 1.91864L15.9681 8.00006L9.8859 14.0823" stroke="#F46530" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></Link>
                    </div>
                     </div>
                    </div>
                    </div>
               </div>
               <div className='col-lg-4'>
                    <div className='blog-cover'>
                       <div>
                       <img src={blogimg} />
                     <div className='bg-content'>
                            <div className='dte'><p>Alec Whitten <span>• 17 Jan 2022</span></p></div>
                     
                        <strong>Bill Walsh leadership lessons</strong>
                        <p>Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Like to know ......</p>
                    <div className='read-more-blg'>
                        <Link to="/blogdetails">Read More <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.998535 8.00037L15.9692 7.99936" stroke="#F46530" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.88672 1.91864L15.9681 8.00006L9.8859 14.0823" stroke="#F46530" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></Link>
                    </div>
                     </div>
                    </div>
                    </div>
               </div>
             
           </div>
          <div className='paging'>
          <Pagination>
      <Pagination.First />
      <Pagination.Prev />
      <Pagination.Item>{1}</Pagination.Item>
      <Pagination.Ellipsis />

      <Pagination.Item>{10}</Pagination.Item>
      <Pagination.Item>{11}</Pagination.Item>
      <Pagination.Item active>{12}</Pagination.Item>
      <Pagination.Item>{13}</Pagination.Item>
      <Pagination.Item disabled>{14}</Pagination.Item>

      <Pagination.Ellipsis />
      <Pagination.Item>{20}</Pagination.Item>
      <Pagination.Next />
      <Pagination.Last />
    </Pagination>
          </div>
       </div>
   </section>        </div>
      </Layout>
    </>
  )
}

export default Blog
