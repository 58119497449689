// import React from 'react'
// import Layout from './Layout'
// import Accordion from 'react-bootstrap/Accordion';

// const FAQ = () => {
//     return (
//         <>
//             <Layout>
//                 <section className='faq'>
//                     <div className='top-heading'>
//                         <h2>FAQ</h2>

//                     </div>
//                     <div className='container'>
//                         <div className='row '>
//                             <div className='col-lg-9 mx-auto'>
//                                 <Accordion defaultActiveKey="0">
//                                     <Accordion.Item eventKey="0">
//                                         <Accordion.Header>What is the Modern Farmhouse style?</Accordion.Header>
//                                         <Accordion.Body>
//                                             Modern Farmhouse style in home plans combines the charm of traditional farmhouses with contemporary design elements. It typically blends rustic and modern aesthetics, creating a warm and inviting atmosphere.
//                                         </Accordion.Body>
//                                     </Accordion.Item>
//                                     <Accordion.Item eventKey="1">
//                                         <Accordion.Header>What are the key characteristics of the Modern Farmhouse style?</Accordion.Header>
//                                         <Accordion.Body>
//                                             Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//                                             eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
//                                             minim veniam, quis nostrud exercitation ullamco laboris nisi ut
//                                             aliquip ex ea commodo consequat. Duis aute irure dolor in
//                                             reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
//                                             pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
//                                             culpa qui officia deserunt mollit anim id est laborum.
//                                         </Accordion.Body>
//                                     </Accordion.Item>
//                                     <Accordion.Item eventKey="2">
//                                         <Accordion.Header>Are Modern Farmhouse homes typically large or small?</Accordion.Header>
//                                         <Accordion.Body>
//                                             Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//                                             eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
//                                             minim veniam, quis nostrud exercitation ullamco laboris nisi ut
//                                             aliquip ex ea commodo consequat. Duis aute irure dolor in
//                                             reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
//                                             pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
//                                             culpa qui officia deserunt mollit anim id est laborum.
//                                         </Accordion.Body>
//                                     </Accordion.Item>
//                                     <Accordion.Item eventKey="3">
//                                         <Accordion.Header>What materials are commonly used in Modern Farmhouse construction?</Accordion.Header>
//                                         <Accordion.Body>
//                                             Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//                                             eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
//                                             minim veniam, quis nostrud exercitation ullamco laboris nisi ut
//                                             aliquip ex ea commodo consequat. Duis aute irure dolor in
//                                             reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
//                                             pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
//                                             culpa qui officia deserunt mollit anim id est laborum.
//                                         </Accordion.Body>
//                                     </Accordion.Item>
//                                     <Accordion.Item eventKey="4">
//                                         <Accordion.Header>What type of roofing is typical in Modern Farmhouse homes?</Accordion.Header>
//                                         <Accordion.Body>
//                                             Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
//                                             eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
//                                             minim veniam, quis nostrud exercitation ullamco laboris nisi ut
//                                             aliquip ex ea commodo consequat. Duis aute irure dolor in
//                                             reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
//                                             pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
//                                             culpa qui officia deserunt mollit anim id est laborum.
//                                         </Accordion.Body>
//                                     </Accordion.Item>
//                                 </Accordion>
//                             </div>
//                         </div>
//                     </div>
//                 </section>
//             </Layout>
//         </>
//     )
// }

// export default FAQ



import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Layout from './Layout';
import Accordion from 'react-bootstrap/Accordion';

const FAQ = () => {
    const [faqData, setFaqData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFaqData = async () => {
            try {
                const response = await axios.get('http://161.35.110.165:3009/admin/faq'); // Replace with your actual API endpoint
                setFaqData(response.data.data);
                setLoading(false);
            } catch (err) {
                setError("Failed to fetch FAQ data.");
                setLoading(false);
            }
        };

        fetchFaqData();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <Layout>
            <section className='faq'>
                <div className='top-heading'>
                    <h2>FAQ</h2>
                </div>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-9 mx-auto'>
                            <Accordion defaultActiveKey="0">
                                {faqData.map((faq, index) => (
                                    <Accordion.Item eventKey={index.toString()} key={faq.id}>
                                        <Accordion.Header>{faq.question}</Accordion.Header>
                                        <Accordion.Body>{faq.answer}</Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default FAQ;
