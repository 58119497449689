// // components/DashboardLayout.js
// import React, { useEffect } from "react";
// import Layout from "./Layout";
// import Form from 'react-bootstrap/Form';
// import Accordion from 'react-bootstrap/Accordion';
// import houseicon from '../assets/images/house-icon.png';
// import aspect_ratio from '../assets/images/aspect_ratio.svg'
// import floor from '../assets/images/floor.svg'
// import apartment from '../assets/images/apartment.svg'
// import directions_car from '../assets/images/directions_car.svg'
// import garage_door from '../assets/images/garage_door.png'
// import garage_home from '../assets/images/garage_home.png'
// import bathtub from '../assets/images/bathtub.png'
// import countertops from '../assets/images/countertops.png'

// import imghouse1 from "../assets/images/img1.png";
// import exclusive from "../assets/images/exclusive.svg";
// import youtubr from "../assets/images/youtubr.svg";
// import fav from "../assets/images/fav.svg";
// import dry_cleaning from "../assets/images/dry_cleaning.svg"
// import king_bed from "../assets/images/king_bed.png"
// import pool from "../assets/images/pool.png"
// import foundation from "../assets/images/foundation.png"
// import { useDispatch, useSelector } from "react-redux";
// import { Link, useNavigate } from "react-router-dom";
// import { listPropertyAction } from "../redux/actions/PropertyAction";

// const PropertyList = () => {
//   const dispatch = useDispatch();
//   const { propertyList } = useSelector((state) => state.property)
//   const navigate = useNavigate();

//   useEffect(() => {
//     dispatch(listPropertyAction());
//   }, []);

//   console.log("propertyList", propertyList);

//   return (
//     <Layout>
//       <section className='search'>
//         <div className='container'>
//           <div className='row'>
//             <div className='col-lg-4'>
//               <div className='filters'>
//                 <div className='fil-title'>
//                   <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
//                     <path d="M8.25 17.75V12.25H9.75V14.25H17.75V15.75H9.75V17.75H8.25ZM0.25 15.75V14.25H5.75V15.75H0.25ZM4.25 11.75V9.75H0.25V8.25H4.25V6.25H5.75V11.75H4.25ZM8.25 9.75V8.25H17.75V9.75H8.25ZM12.25 5.75V0.25H13.75V2.25H17.75V3.75H13.75V5.75H12.25ZM0.25 3.75V2.25H9.75V3.75H0.25Z" fill="#F46530" />
//                   </svg>
//                   <span>Filters</span>
//                 </div>
//                 <div className='typeflor'>
//                   <h5>Type</h5>
//                   <div className='d-flex'>
//                     <div className='planimg'>
//                       <button type="button" class="btn btn-secondary"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
//                         <path d="M13.2077 17.7083V14.5417H5.29102C4.8556 14.5417 4.48286 14.3866 4.17279 14.0766C3.86272 13.7665 3.70768 13.3938 3.70768 12.9583V5.04167H0.541016V3.45833H3.70768V0.291667H5.29102V12.9583H17.9577V14.5417H14.791V17.7083H13.2077ZM13.2077 11.375V5.04167H6.87435V3.45833H13.2077C13.6431 3.45833 14.0158 3.61337 14.3259 3.92344C14.636 4.23351 14.791 4.60625 14.791 5.04167V11.375H13.2077Z" fill="white" />
//                       </svg> Plan Images</button>
//                     </div>
//                     <div className='florpln'>
//                       <button type="button" class="btn btn-outline-secondary"><svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
//                         <mask id="mask0_686_25882" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
//                           <rect x="0.25" y="9.53674e-07" width="24" height="24" fill="#D9D9D9" />
//                         </mask>
//                         <g mask="url(#mask0_686_25882)">
//                           <path d="M3 20.1667V18.3333H6.20833V14.2083H10.3333V10.0833H14.4583V5.95833H18.5833V2.75H20.4167V7.79167H16.2917V11.9167H12.1667V16.0417H8.04167V20.1667H3Z" fill="#666666" />
//                         </g>
//                       </svg> Floor Plans</button>
//                     </div>
//                   </div>
//                 </div>
//                 <div className='keyword'>
//                   <Form>
//                     <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
//                       <Form.Label>Enter a keyword</Form.Label>
//                       <div className='d-flex'>
//                         <Form.Control type="email" placeholder="Keeping  room" />
//                         <button type="button" class="btn btn-secondary ms-2">
//                           <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
//                             <path d="M1.41691 15.4879L6.90011 10.0045C7.33661 10.3649 7.8386 10.6469 8.40606 10.8506C8.97352 11.0543 9.56055 11.1562 10.1672 11.1562C11.6593 11.1562 12.9221 10.6396 13.9556 9.60637C14.9891 8.57315 15.5059 7.31063 15.5059 5.81879C15.5059 4.32709 14.9893 3.06413 13.956 2.0299C12.9228 0.995811 11.6603 0.478767 10.1685 0.478767C8.67677 0.478767 7.4138 0.995519 6.37957 2.02902C5.34548 3.06253 4.82844 4.32535 4.82844 5.81748C4.82844 6.44096 4.93306 7.03643 5.14229 7.60389C5.35167 8.17135 5.63096 8.66489 5.98017 9.08452L0.496971 14.5677L1.41691 15.4879ZM10.1672 9.84688C9.04227 9.84688 8.08952 9.4565 7.3089 8.67573C6.52813 7.89511 6.13775 6.94236 6.13775 5.81748C6.13775 4.6926 6.52813 3.73984 7.3089 2.95922C8.08952 2.17846 9.04227 1.78807 10.1672 1.78807C11.292 1.78807 12.2448 2.17846 13.0254 2.95922C13.8062 3.73984 14.1966 4.6926 14.1966 5.81748C14.1966 6.94236 13.8062 7.89511 13.0254 8.67573C12.2448 9.4565 11.292 9.84688 10.1672 9.84688Z" fill="white" />
//                           </svg>

//                         </button>
//                       </div>
//                     </Form.Group>

//                   </Form>
//                 </div>
//                 <div className='keyword'>
//                   <Form>
//                     <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
//                       <Form.Label>Total Heated Area (Sq Ft.)</Form.Label>
//                       <div className='d-flex total-area'>
//                         <Form.Control type="text" placeholder="00" />
//                         <Form.Control type="text" placeholder="00" />
//                         <button type="button" class="btn btn-secondary ms-2">
//                           <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
//                             <path d="M1.41691 15.4879L6.90011 10.0045C7.33661 10.3649 7.8386 10.6469 8.40606 10.8506C8.97352 11.0543 9.56055 11.1562 10.1672 11.1562C11.6593 11.1562 12.9221 10.6396 13.9556 9.60637C14.9891 8.57315 15.5059 7.31063 15.5059 5.81879C15.5059 4.32709 14.9893 3.06413 13.956 2.0299C12.9228 0.995811 11.6603 0.478767 10.1685 0.478767C8.67677 0.478767 7.4138 0.995519 6.37957 2.02902C5.34548 3.06253 4.82844 4.32535 4.82844 5.81748C4.82844 6.44096 4.93306 7.03643 5.14229 7.60389C5.35167 8.17135 5.63096 8.66489 5.98017 9.08452L0.496971 14.5677L1.41691 15.4879ZM10.1672 9.84688C9.04227 9.84688 8.08952 9.4565 7.3089 8.67573C6.52813 7.89511 6.13775 6.94236 6.13775 5.81748C6.13775 4.6926 6.52813 3.73984 7.3089 2.95922C8.08952 2.17846 9.04227 1.78807 10.1672 1.78807C11.292 1.78807 12.2448 2.17846 13.0254 2.95922C13.8062 3.73984 14.1966 4.6926 14.1966 5.81748C14.1966 6.94236 13.8062 7.89511 13.0254 8.67573C12.2448 9.4565 11.292 9.84688 10.1672 9.84688Z" fill="white" />
//                           </svg>

//                         </button>
//                       </div>
//                     </Form.Group>

//                   </Form>
//                 </div>
//                 <Form.Label>Category</Form.Label>
//                 <Form.Select aria-label="Default select example">
//                   <option>Open this select menu</option>
//                   <option value="1">One</option>
//                   <option value="2">Two</option>
//                   <option value="3">Three</option>
//                 </Form.Select>
//                 <Form.Label>Bathrooms</Form.Label>
//                 <Form.Select aria-label="Default select example">
//                   <option>Open this select menu</option>
//                   <option value="1">One</option>
//                   <option value="2">Two</option>
//                   <option value="3">Three</option>
//                 </Form.Select>
//                 <hr></hr>
//                 <Accordion defaultActiveKey="0">
//                   <Accordion.Item eventKey="0">
//                     <Accordion.Header><img src={houseicon} />Plan Type</Accordion.Header>
//                     <Accordion.Body>
//                       <div className='all-options'>
//                         <Form>
//                           {['checkbox'].map((type) => (
//                             <div key={`default-${type}`} className="mb-3">
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Stand-Alone Garages  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Other (sheds, pool houses, offices) `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                             </div>
//                           ))}
//                         </Form>
//                       </div>
//                     </Accordion.Body>
//                   </Accordion.Item>
//                   <Accordion.Item eventKey="1">
//                     <Accordion.Header><img src={aspect_ratio} />Footprint</Accordion.Header>
//                     <Accordion.Body>
//                       <div className='all-options'>
//                         <Form>
//                           {['checkbox'].map((type) => (
//                             <div key={`default-${type}`} className="mb-3">
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Stand-Alone Garages  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Other (sheds, pool houses, offices) `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                             </div>
//                           ))}
//                         </Form>
//                       </div>
//                     </Accordion.Body>
//                   </Accordion.Item>
//                   <Accordion.Item eventKey="2">
//                     <Accordion.Header><img src={floor} />Floor</Accordion.Header>
//                     <Accordion.Body>
//                       <div className='all-options'>
//                         <Form>
//                           {['checkbox'].map((type) => (
//                             <div key={`default-${type}`} className="mb-3">
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Stand-Alone Garages  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Other (sheds, pool houses, offices) `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                             </div>
//                           ))}
//                         </Form>
//                       </div>
//                     </Accordion.Body>
//                   </Accordion.Item>
//                   <Accordion.Item eventKey="3">
//                     <Accordion.Header><img src={apartment} />Styles</Accordion.Header>
//                     <Accordion.Body>
//                       <div className='all-options'>
//                         <Form>
//                           {['checkbox'].map((type) => (
//                             <div key={`default-${type}`} className="mb-3">
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Stand-Alone Garages  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Other (sheds, pool houses, offices) `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                             </div>
//                           ))}
//                         </Form>
//                       </div>
//                     </Accordion.Body>
//                   </Accordion.Item>
//                   <Accordion.Item eventKey="4">
//                     <Accordion.Header><img src={directions_car} />Number of Vehicles</Accordion.Header>
//                     <Accordion.Body>
//                       <div className='all-options'>
//                         <Form>
//                           {['checkbox'].map((type) => (
//                             <div key={`default-${type}`} className="mb-3">
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Stand-Alone Garages  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Other (sheds, pool houses, offices) `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                             </div>
//                           ))}
//                         </Form>
//                       </div>
//                     </Accordion.Body>
//                   </Accordion.Item>
//                   <Accordion.Item eventKey="5">
//                     <Accordion.Header><img src={garage_door} />Garage Type</Accordion.Header>
//                     <Accordion.Body>
//                       <div className='all-options'>
//                         <Form>
//                           {['checkbox'].map((type) => (
//                             <div key={`default-${type}`} className="mb-3">
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Stand-Alone Garages  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Other (sheds, pool houses, offices) `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                             </div>
//                           ))}
//                         </Form>
//                       </div>
//                     </Accordion.Body>
//                   </Accordion.Item>
//                   <Accordion.Item eventKey="6">
//                     <Accordion.Header><img src={garage_home} />Garage Location</Accordion.Header>
//                     <Accordion.Body>
//                       <div className='all-options'>
//                         <Form>
//                           {['checkbox'].map((type) => (
//                             <div key={`default-${type}`} className="mb-3">
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Stand-Alone Garages  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Other (sheds, pool houses, offices) `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                             </div>
//                           ))}
//                         </Form>
//                       </div>
//                     </Accordion.Body>
//                   </Accordion.Item>
//                   <Accordion.Item eventKey="7">
//                     <Accordion.Header><img src={bathtub} />Bedroom & Bath Options</Accordion.Header>
//                     <Accordion.Body>
//                       <div className='all-options'>
//                         <Form>
//                           {['checkbox'].map((type) => (
//                             <div key={`default-${type}`} className="mb-3">
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Stand-Alone Garages  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Other (sheds, pool houses, offices) `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                             </div>
//                           ))}
//                         </Form>
//                       </div>
//                     </Accordion.Body>
//                   </Accordion.Item>
//                   <Accordion.Item eventKey="8">
//                     <Accordion.Header><img src={countertops} />Kitchen and Dining</Accordion.Header>
//                     <Accordion.Body>
//                       <div className='all-options'>
//                         <Form>
//                           {['checkbox'].map((type) => (
//                             <div key={`default-${type}`} className="mb-3">
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Stand-Alone Garages  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Other (sheds, pool houses, offices) `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                             </div>
//                           ))}
//                         </Form>
//                       </div>
//                     </Accordion.Body>
//                   </Accordion.Item>
//                   <Accordion.Item eventKey="9">
//                     <Accordion.Header><img src={dry_cleaning} />Kitchen and Dining</Accordion.Header>
//                     <Accordion.Body>
//                       <div className='all-options'>
//                         <Form>
//                           {['checkbox'].map((type) => (
//                             <div key={`default-${type}`} className="mb-3">
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Stand-Alone Garages  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Other (sheds, pool houses, offices) `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                             </div>
//                           ))}
//                         </Form>
//                       </div>
//                     </Accordion.Body>
//                   </Accordion.Item>
//                   <Accordion.Item eventKey="10">
//                     <Accordion.Header><img src={king_bed} />Kitchen and Dining</Accordion.Header>
//                     <Accordion.Body>
//                       <div className='all-options'>
//                         <Form>
//                           {['checkbox'].map((type) => (
//                             <div key={`default-${type}`} className="mb-3">
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Stand-Alone Garages  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Other (sheds, pool houses, offices) `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                             </div>
//                           ))}
//                         </Form>
//                       </div>
//                     </Accordion.Body>
//                   </Accordion.Item>
//                   <Accordion.Item eventKey="11">
//                     <Accordion.Header><img src={pool} />Kitchen and Dining</Accordion.Header>
//                     <Accordion.Body>
//                       <div className='all-options'>
//                         <Form>
//                           {['checkbox'].map((type) => (
//                             <div key={`default-${type}`} className="mb-3">
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Stand-Alone Garages  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Other (sheds, pool houses, offices) `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                             </div>
//                           ))}
//                         </Form>
//                       </div>
//                     </Accordion.Body>
//                   </Accordion.Item>
//                   <Accordion.Item eventKey="12">
//                     <Accordion.Header><img src={foundation} />Kitchen and Dining</Accordion.Header>
//                     <Accordion.Body>
//                       <div className='all-options'>
//                         <Form>
//                           {['checkbox'].map((type) => (
//                             <div key={`default-${type}`} className="mb-3">
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Stand-Alone Garages  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Other (sheds, pool houses, offices) `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                               <div className='d-flex'>
//                                 <Form.Check // prettier-ignore
//                                   type={type}
//                                   id={`default-${type}`}
//                                   label={`Single-Family Homes  `}
//                                 />
//                                 <span className='all-tot'>(1,779)</span>
//                               </div>
//                             </div>
//                           ))}
//                         </Form>
//                       </div>
//                     </Accordion.Body>
//                   </Accordion.Item>
//                 </Accordion>
//               </div>
//             </div>
           
           
           
           
           
//            {/* ---------------------------------------------------------------main lisitng div------------------------------------------------------------------------------------- */}
//             <div className='col-lg-8'>
//               <div className='farmplans'>
//                 <span>1,854 plans found!</span>
//                 <h4></h4>
//               </div>
//               <div className='plans-farms'>
//                 {
//                   Array.isArray(propertyList) && propertyList?.length > 0 ?
//                     propertyList?.map((res, index) => {
//                       return <>
//                         <div className="wrap" key={res?._id}>
//                           <Link to={`/property/${res?._id}`} className="custom-link">
//                             <div className="img-h">
//                               <img src={res?.main_images?.length > 0 ? res?.main_images[0] : imghouse1} style={{ height: '250px', width: '280px' }} />
//                               <div className="sociali-btn">
//                                 <div className="youtube">
//                                   <img src={youtubr} />
//                                 </div>
//                                 <div className="fav">
//                                   <img src={fav} />
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="ex-tag">
//                               <img src={exclusive} />
//                             </div>
//                             <div className="img-content">
//                               <div className="h-details">
//                                 <div className="area">
//                                   <strong>{res?.sq_ft || '0'}</strong>
//                                   <p className="mb-0">Sq. Ft.</p>
//                                 </div>
//                                 <hr></hr>
//                                 <div className="area">
//                                   <strong>{res?.beds || '0'}</strong>
//                                   <p className="mb-0">Bed</p>
//                                 </div>
//                                 <hr></hr>
//                                 <div className="area">
//                                   <strong>{res?.baths || '0'}</strong>
//                                   <p className="mb-0">Bath</p>
//                                 </div>
//                                 <hr></hr>
//                                 <div className="area">
//                                   <strong>{res?.story || '0'}</strong>
//                                   <p className="mb-0">Story</p>
//                                 </div>
//                                 <hr></hr>
//                                 <div className="area">
//                                   <strong>{res?.cars || '0'}</strong>
//                                   <p className="mb-0">Cars</p>
//                                 </div>
//                               </div>
//                               <hr></hr>
//                               <div className="d-flex justify-content-between">
//                                 <div className="pln-no">
//                                   <span>Plan Number</span>
//                                   <h2>{res?.plan_number ? `#${res?.plan_number}` : 'N/A'}</h2>
//                                 </div>
//                                 <div className="pln-no">
//                                   <span>Starts From</span>
//                                   <h2 className="price">${res?.price || '0'}</h2>
//                                 </div>
//                               </div>
//                             </div>
//                           </Link>
//                         </div>
//                       </>
//                     })
//                     : <p className="text-center">No Data Found</p>
//                 }
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </Layout>
//   );
// };

// export default PropertyList;


































// components/DashboardLayout.js
import React, { useEffect,useState } from "react";
import Layout from "./Layout";
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import houseicon from '../assets/images/house-icon.png';
import aspect_ratio from '../assets/images/aspect_ratio.svg'
import floor from '../assets/images/floor.svg'
import apartment from '../assets/images/apartment.svg'
import directions_car from '../assets/images/directions_car.svg'
import garage_door from '../assets/images/garage_door.png'
import garage_home from '../assets/images/garage_home.png'
import bathtub from '../assets/images/bathtub.png'
import countertops from '../assets/images/countertops.png'
import {  Row, Col } from 'react-bootstrap';

import {
  additional_rooms_Select_data,
  bed_bath_options_Select_data,
  collections_Select_data,
  exterior_walls_Select_data,
  foundation_Select_data,
  garage_location_Select_data,
  garage_type_Select_data,
  kitchen_dining_Select_data,
  laundry_location_Select_data,
  lot_features_Select_data,
  outdoor_features_Select_data,
  plan_style_select_data,
  plan_type_Select_data,
  special_features_Select_data,
  common_number_data,
  floor_data
} from '../constant/constant';

import imghouse1 from "../assets/images/img1.png";
import exclusive from "../assets/images/exclusive.svg";
import youtubr from "../assets/images/youtubr.svg";
import fav from "../assets/images/fav.svg";
import dry_cleaning from "../assets/images/dry_cleaning.svg"
import king_bed from "../assets/images/king_bed.png"
import pool from "../assets/images/pool.png"
import foundation from "../assets/images/foundation.png"
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { listPropertyActiondata } from "../redux/actions/PropertyAction";
import { addToFavourites } from "../redux/reducers/favourites";
import { toast } from "react-toastify";
import Pagination from "../commonComponent/pagination";
import { getPlanStyles } from "../redux/reducers/planStylesSlice";
import Api from "../service/Api";

const PropertyList = ({ defaultFilters = {} }) => {
  const dispatch = useDispatch();
  const { propertyList ,totalItems} = useSelector((state) => state.property)
  const location = useLocation(); // Get the current location
  const navigate = useNavigate();
  const [styles, setStyles] = useState([]); // To store styles from API

  const statefornav = location.state

if(statefornav){
  defaultFilters = statefornav.selectedFilters
}

  const [currentPage, setCurrentPage] = useState(0);  // Track current page
  const itemsPerPage = 10;  // Define items per page (or get it dynamically if needed)
  

  // const handleAddToFavourites = (property_id) => {
  //   dispatch(addToFavourites(property_id));
  //   dispatch(listPropertyAction)
  //   toast.success('Added to favorites')
  // };

  const handleAddToFavourites = (property_id) => {
    dispatch(addToFavourites(property_id))
      .then(() => {
        // After successfully adding to favorites, dispatch listPropertyAction to get the updated list
        const formattedFilters = formatFilters(filters); // Format filters before dispatching
        dispatch(listPropertyActiondata(formattedFilters)); // Fetch the latest data
      })
      .catch((error) => {
        toast.error('Failed to add to favorites');
        console.error("Error adding to favorites:", error);
      });
  };
  








  // Handle select change
  const handleSelectChange = (event) => {
    setFilters({ ...filters, bathrooms: event.target.value }); // Update bathrooms filter
  };
  useEffect(() => {
    setFilters(initialFilters); // Reset to initial filters when the location changes
  }, [location]);

  const   initialFilters  ={
    keyword: '',
    min_sq_ft: '',
    max_sq_ft: '',
    bedrooms: '',
    bathrooms: '',
    min_footprint_width: '',
    max_footprint_width: '',
    min_footprint_depth: '',
    max_footprint_depth: '',
    min_footprint_height: '',
    max_footprint_height: '',
    plan_type: [],
    plan_style_id: [],
    floors: '',
    no_of_vehicles: [],
    garage_type: [],
    garage_location: [],
    bed_bath_options: [],
    kitchen_dinning: [],
    laundry_location: [],
    additional_rooms: [],
    outdoor_features: [],
    foundation: [],
    special_features: [],
    exterior_walls: [],
    collections: [],
    lot_features: [],
    sort_by: '',
    pagination: currentPage,
    limit: itemsPerPage,
    ...(Object.keys(defaultFilters).length > 0 ? defaultFilters : {}),
  };
  let debounceTimer;
  const [filters, setFilters] = useState(initialFilters);
  // const { styles, loading: stylesLoading, error: stylesError } = useSelector((state) => state.planStyles);

  // Function to update form state
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFilters((prevState) => ({
      ...prevState,
      
      [name]: value
    }));
  };

// Function to format filters before API call
const formatFilters = (filters) => {
  const formattedFilters = { ...filters };

  // Loop through each key in filters
  Object.keys(formattedFilters).forEach((key) => {
    if (Array.isArray(formattedFilters[key]) && formattedFilters[key].length > 0) {
      // Convert arrays to the desired format "[x,y]"
      formattedFilters[key] = `[${formattedFilters[key].join(",")}]`;
    }
  });

  return formattedFilters;
};
const formatFiltersbynavigation = (na) => {
  const formattedFilters = { ...filters };

  // Loop through each key in filters
  Object.keys(formattedFilters).forEach((key) => {
    if (Array.isArray(formattedFilters[key]) && formattedFilters[key].length > 0) {
      // Convert arrays to the desired format "[x,y]"
      formattedFilters[key] = `[${formattedFilters[key].join(",")}]`;
    }
  });

  return formattedFilters;
}

    // Function to handle checkbox changes for arrays (using index as value)
    const handleCheckboxChange = (index, filterKey) => {
      setFilters((prevState) => {
        const isChecked = prevState[filterKey].includes(index);  // Check if the index is already selected
        const updatedArray = isChecked
          ? prevState[filterKey].filter((item) => item !== index)  // Remove index if unchecked
          : [...prevState[filterKey], index];                     // Add index if checked
        return { ...prevState, [filterKey]: updatedArray };
      });
    };

    const handleCheckboxChangestyle = (plan_style_id) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        plan_style_id: prevFilters.plan_style_id === plan_style_id ? null : plan_style_id,
      }));
    };

// Function to trigger API call when filters change (with debounce)
useEffect(() => {
  if (debounceTimer) clearTimeout(debounceTimer); // Clear the previous debounce if a new change occurs
  debounceTimer = setTimeout(() => {
    // Format filters before dispatching
    const formattedFilters = formatFilters(filters);
    
    // Dispatch action with formatted filters
    dispatch(listPropertyActiondata(formattedFilters));
    // console.log(styles,'----------------->')
    // dispatch(getPlanStyles());
  }, 100); // 100ms debounce delay
}, [filters]); // Effect triggers whenever filters change

useEffect(() => {
  const fetchStyles = async () => {
    try {
      const response = await Api.get('/plan-styles');
      // Format the API response to match the checkbox structure
      console.log('----------sds---->>.',response);

      const formattedStyles = response.data.data.map(item => ({
        value: item._id, // Assuming _id is the unique identifier
        label: item.name, // Assuming 'name' is the label
      }));
      setStyles(formattedStyles); // Set the formatted styles
      // setLoading(false); // Stop loading once data is fetched
      console.log('-------------->>.',formattedStyles);
    } catch (err) {
      // setError('Failed to fetch data'); // Handle API errors
      // setLoading(false); // Stop loading in case of error
    }
  };

  fetchStyles();
}, []);


const handlePageChange = (pageNumber) => {
  setCurrentPage(pageNumber);
  setFilters({ ...filters, pagination: pageNumber });
};

  const handleClearFilters = () => {
    // Reset the filters to their initial state
    setFilters(initialFilters);
  };


  return (
    <Layout>
      <section className='search'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-4'>
              <div className='filters'>
                <div className='fil-title'>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.25 17.75V12.25H9.75V14.25H17.75V15.75H9.75V17.75H8.25ZM0.25 15.75V14.25H5.75V15.75H0.25ZM4.25 11.75V9.75H0.25V8.25H4.25V6.25H5.75V11.75H4.25ZM8.25 9.75V8.25H17.75V9.75H8.25ZM12.25 5.75V0.25H13.75V2.25H17.75V3.75H13.75V5.75H12.25ZM0.25 3.75V2.25H9.75V3.75H0.25Z" fill="#F46530" />
                  </svg>
                  <span>Filters</span>
                  <button type="button"  onClick={handleClearFilters} class="btn btn-dark">Clear filter</button>
                  </div>
                {/* <div className='typeflor'>
                  <h5>Type</h5>
                  <div className='d-flex'>
                    <div className='planimg'>
                      <button type="button" class="btn btn-secondary"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.2077 17.7083V14.5417H5.29102C4.8556 14.5417 4.48286 14.3866 4.17279 14.0766C3.86272 13.7665 3.70768 13.3938 3.70768 12.9583V5.04167H0.541016V3.45833H3.70768V0.291667H5.29102V12.9583H17.9577V14.5417H14.791V17.7083H13.2077ZM13.2077 11.375V5.04167H6.87435V3.45833H13.2077C13.6431 3.45833 14.0158 3.61337 14.3259 3.92344C14.636 4.23351 14.791 4.60625 14.791 5.04167V11.375H13.2077Z" fill="white" />
                      </svg> Plan Images</button>
                    </div>
                    <div className='florpln'>
                      <button type="button" class="btn btn-outline-secondary"><svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_686_25882" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                          <rect x="0.25" y="9.53674e-07" width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_686_25882)">
                          <path d="M3 20.1667V18.3333H6.20833V14.2083H10.3333V10.0833H14.4583V5.95833H18.5833V2.75H20.4167V7.79167H16.2917V11.9167H12.1667V16.0417H8.04167V20.1667H3Z" fill="#666666" />
                        </g>
                      </svg> Floor Plans</button>
                    </div>
                  </div>
                </div> */}
                <div className='keyword'>
                  <Form>
                    <Form.Group className="mb-3" controlId="keyword">
                      <Form.Label>Enter a keyword</Form.Label>
                      <div className='d-flex'>
                        <Form.Control type="text"
          name="keyword"
          value={filters.keyword}
          onChange={handleInputChange}
          placeholder="Enter keyword" />
                        <button type="button" class="btn btn-secondary ms-2">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.41691 15.4879L6.90011 10.0045C7.33661 10.3649 7.8386 10.6469 8.40606 10.8506C8.97352 11.0543 9.56055 11.1562 10.1672 11.1562C11.6593 11.1562 12.9221 10.6396 13.9556 9.60637C14.9891 8.57315 15.5059 7.31063 15.5059 5.81879C15.5059 4.32709 14.9893 3.06413 13.956 2.0299C12.9228 0.995811 11.6603 0.478767 10.1685 0.478767C8.67677 0.478767 7.4138 0.995519 6.37957 2.02902C5.34548 3.06253 4.82844 4.32535 4.82844 5.81748C4.82844 6.44096 4.93306 7.03643 5.14229 7.60389C5.35167 8.17135 5.63096 8.66489 5.98017 9.08452L0.496971 14.5677L1.41691 15.4879ZM10.1672 9.84688C9.04227 9.84688 8.08952 9.4565 7.3089 8.67573C6.52813 7.89511 6.13775 6.94236 6.13775 5.81748C6.13775 4.6926 6.52813 3.73984 7.3089 2.95922C8.08952 2.17846 9.04227 1.78807 10.1672 1.78807C11.292 1.78807 12.2448 2.17846 13.0254 2.95922C13.8062 3.73984 14.1966 4.6926 14.1966 5.81748C14.1966 6.94236 13.8062 7.89511 13.0254 8.67573C12.2448 9.4565 11.292 9.84688 10.1672 9.84688Z" fill="white" />
                          </svg>

                        </button>
                      </div>
                    </Form.Group>

                  </Form>
                </div>
                <div className='keyword'>
                  <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Total Heated Area (Sq Ft.)</Form.Label>
                      <div className='d-flex total-area'>
                        <Form.Control   name="min_sq_ft"
          value={filters.min_sq_ft}
          onChange={handleInputChange} type="text" placeholder="min" />
                        <Form.Control  name="max_sq_ft"
          value={filters.max_sq_ft}
          onChange={handleInputChange} type="text" placeholder="max" />
                        <button type="button" class="btn btn-secondary ms-2">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.41691 15.4879L6.90011 10.0045C7.33661 10.3649 7.8386 10.6469 8.40606 10.8506C8.97352 11.0543 9.56055 11.1562 10.1672 11.1562C11.6593 11.1562 12.9221 10.6396 13.9556 9.60637C14.9891 8.57315 15.5059 7.31063 15.5059 5.81879C15.5059 4.32709 14.9893 3.06413 13.956 2.0299C12.9228 0.995811 11.6603 0.478767 10.1685 0.478767C8.67677 0.478767 7.4138 0.995519 6.37957 2.02902C5.34548 3.06253 4.82844 4.32535 4.82844 5.81748C4.82844 6.44096 4.93306 7.03643 5.14229 7.60389C5.35167 8.17135 5.63096 8.66489 5.98017 9.08452L0.496971 14.5677L1.41691 15.4879ZM10.1672 9.84688C9.04227 9.84688 8.08952 9.4565 7.3089 8.67573C6.52813 7.89511 6.13775 6.94236 6.13775 5.81748C6.13775 4.6926 6.52813 3.73984 7.3089 2.95922C8.08952 2.17846 9.04227 1.78807 10.1672 1.78807C11.292 1.78807 12.2448 2.17846 13.0254 2.95922C13.8062 3.73984 14.1966 4.6926 14.1966 5.81748C14.1966 6.94236 13.8062 7.89511 13.0254 8.67573C12.2448 9.4565 11.292 9.84688 10.1672 9.84688Z" fill="white" />
                          </svg>

                        </button>
                      </div>
                    </Form.Group>

                  </Form>
                </div>
                {/* <Form.Label>Category</Form.Label>
                <Form.Select aria-label="Default select example">
                  <option>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select> */}
                <Form.Label>Bathrooms</Form.Label>
                <Form.Select aria-label="Default select example" onChange={handleSelectChange}>
                  <option>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
                <hr></hr>
                <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
      <Accordion.Header>
        <img src={houseicon} alt="House Icon" />
        Plan Type
      </Accordion.Header>
      <Accordion.Body>
        <div className='all-options'>
          <Form>
            {['checkbox'].map((type) => (
              <div key={`default-${type}`} className="mb-3">
                {plan_type_Select_data.map((plan, index) => (
                  <div className='d-flex' key={index}>
                    <Form.Check // prettier-ignore
                      type={type}
                      id={`default-${type}-${index}`}
                      value={index} // Use index as value for filtering
                  checked={filters?.plan_type?.includes(index) || false} // Check if the checkbox is selected
                  onChange={() => handleCheckboxChange(index, 'plan_type')} // Handle checkbox change
                      label={plan.label}
                    />
                    <span className='all-tot'></span>
                  </div>
                ))}
              </div>
            ))}
          </Form>
        </div>
      </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="1">
      <Accordion.Header>
        <img src={aspect_ratio} alt="Aspect Ratio Icon" />
        Footprint
      </Accordion.Header>
      <Accordion.Body>
        <div className='all-options'>
          <Form>
            {/* 1. Added Width, Depth, and Height fields with Min and Max inputs */}
            <Row>
              <Col md={4}>
                <Form.Group controlId="footprintWidthMin">
                  <Form.Label>Width </Form.Label>
                  <Form.Control type="number" placeholder="Min "    name="min_footprint_width"
  value={filters.min_footprint_width}
                        onChange={handleInputChange}/>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="footprintWidthMax">
                  <Form.Label>Width </Form.Label>
                  <Form.Control type="number" placeholder="Max "  name="max_footprint_width"   value={filters.max_footprint_width}
                        onChange={handleInputChange} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={4}>
                <Form.Group controlId="footprintDepthMin">
                  <Form.Label>Depth </Form.Label>
                  <Form.Control type="number" placeholder="Min "  name="min_footprint_depth"  value={filters.min_footprint_depth}
                        onChange={handleInputChange} />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="footprintDepthMax">
                  <Form.Label>Depth </Form.Label>
                  <Form.Control type="number" placeholder="Max " name="max_footprint_depth"   value={filters.max_footprint_depth}
                        onChange={handleInputChange} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={4}>
                <Form.Group controlId="footprintHeightMin">
                  <Form.Label>Height </Form.Label>
                  <Form.Control type="number" placeholder="Min "  name="min_footprint_height" value={filters.min_footprint_height}
                        onChange={handleInputChange} />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="footprintHeightMax">
                  <Form.Label>Height </Form.Label>
                  <Form.Control type="number" placeholder="Max " name="max_footprint_height"  value={filters.max_footprint_height}
                        onChange={handleInputChange} />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
      </Accordion.Body>
    </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header><img src={floor} />Floor</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                             {floor_data.map((commondata , index) =>(
                              <div className='d-flex' key={index}>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}-${index}`} // Unique ID
                                  label={ commondata.label}
                                  value={index} // Use index as value for filtering
                  checked={filters.floors.includes(index)} // Check if the checkbox is selected
                  onChange={() => handleCheckboxChange(index, 'floors')} // Handle checkbox change
                                />
                                {/* <span className='all-tot'>(1,779)</span> */}
                              </div>
                            ))}
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header><img src={apartment} />Styles</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                            {styles.map((style_data,index)=>(
                              <div className='d-flex' key={index}>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}-${index}`} // Unique ID
                                  label={style_data.label}
                                  value={style_data.value} // Use index as value for filtering
                                  checked={filters.plan_style_id == style_data.value} // Check if the current style is selected
                                  onChange={() => handleCheckboxChangestyle(style_data.value, 'plan_style_id')} // Handle checkbox change
                                />
                                <span className='all-tot'></span>
                              </div>
                            ))}
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
  <Accordion.Header><img src={directions_car} alt="car icon" />Number of Vehicles</Accordion.Header>
  <Accordion.Body>
    <div className='all-options'>
      <Form>
        {common_number_data.map((option, index) => (
          <div key={index} className="mb-3 d-flex">
            <Form.Check // prettier-ignore
              type="checkbox"
              id={`vehicle-${index}`}
              label={option.label}
              value={index} // Use index as value for filtering
              // checked={filters?.no_of_vehicles?.includes(index)|| false } // Check if the checkbox is selected
              onChange={() => handleCheckboxChange(index, 'no_of_vehicles')} // Handle checkbox change
            />
            <span className='all-tot'></span>
          </div>
        ))}
      </Form>
    </div>
  </Accordion.Body>
</Accordion.Item>

<Accordion.Item eventKey="5">
  <Accordion.Header><img src={garage_door} alt="garage icon" />Garage Type</Accordion.Header>
  <Accordion.Body>
    <div className='all-options'>
      <Form>
        {garage_type_Select_data?.map((option, index) => (
          <div key={index} className="mb-3 d-flex">
            <Form.Check // prettier-ignore
              type="checkbox"
              id={`garageType-${index}`}
              label={option.label}
              value={index} // Use index as value for filtering
              checked={filters?.garage_type?.includes(index)} // Check if the checkbox is selected
              onChange={() => handleCheckboxChange(index, 'garage_type')} // Handle checkbox change
            />
            <span className='all-tot'></span>
          </div>
        ))}
      </Form>
    </div>
  </Accordion.Body>
</Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header><img src={garage_home} />Garage Location</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                              {garage_location_Select_data.map((garage_location_data ,index)=>(   
                              <div className='d-flex' key={index}>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}-${index}`} // Unique ID
                                  label={garage_location_data.label}
                                  value={index} // Use index as the value
                checked={filters.garage_location.includes(index)}
                onChange={() => handleCheckboxChange(index, 'garage_location')}
                                />
                                <span className='all-tot'></span>
                              </div>
                            ) )}
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header><img src={bathtub} />Bedroom & Bath Options</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                            {bed_bath_options_Select_data.map((bed_bath_data , index)=>(  
                              <div className='d-flex' key={index}>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}-${index}`} // Unique ID
                                  label={bed_bath_data.label}
                                  value={index} // Use index as value for filtering
                  checked={filters.bed_bath_options.includes(index)} // Check if the checkbox is selected
                  onChange={() => handleCheckboxChange(index, 'bed_bath_options')} // Handle checkbox change
                                />
                                <span className='all-tot'></span>
                              </div>
                            ))}
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="8">
                    <Accordion.Header><img src={countertops} />Kitchen and Dining</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                            {kitchen_dining_Select_data.map((kitchen_data , index)=>( 
                              <div className='d-flex' key={index}>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}-${index}`} // Unique ID
                                  label={kitchen_data.label}

                                  value={index} // Use index as value for filtering
                  checked={filters.kitchen_dinning.includes(index)} // Check if the checkbox is selected
                  onChange={() => handleCheckboxChange(index, 'kitchen_dinning')} // Handle checkbox change
                                />
                                <span className='all-tot'></span>
                              </div>
                            )) }
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header><img src={dry_cleaning} />Laundary Location</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                            {laundry_location_Select_data.map((laundary_location_data,index)=>(
                              <div className='d-flex' key={index}>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}-${index}`} // Unique ID
                                  label={laundary_location_data.label}
                                  value={index} // Use index as value for filtering
                  checked={filters.laundry_location.includes(index)} // Check if the checkbox is selected
                  onChange={() => handleCheckboxChange(index, 'laundry_location')} // Handle checkbox change
                                />
                                <span className='all-tot'></span>
                              </div>
                            ))}
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="10">
                    <Accordion.Header><img src={king_bed} />Additional Rooms</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                            {additional_rooms_Select_data.map((additional_room_data,index)=>(

                              <div className='d-flex' key={index}>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${index}`}
                                  label={additional_room_data?.label}
                                  value={index} // Use index as value for filtering
                  checked={filters.additional_rooms.includes(index)} // Check if the checkbox is selected
                  onChange={() => handleCheckboxChange(index, 'additional_rooms')} // Handle checkbox change
                                />
                                <span className='all-tot'></span>
                              </div>
                            ))}
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="11">
                    <Accordion.Header><img src={pool} />Outdoor Features</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">
                            {outdoor_features_Select_data.map((outdoor_data,index)=>( 
                              <div className='d-flex'>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${type}`}
                                  label={outdoor_data?.label}
                                  value={index} // Use index as value for filtering
                  checked={filters.outdoor_features.includes(index)} // Check if the checkbox is selected
                  onChange={() => handleCheckboxChange(index, 'outdoor_features')} // Handle checkbox change
                                />
                                <span className='all-tot'></span>
                              </div>
                            ))}
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="12">
                    <Accordion.Header><img src={foundation} />Foundation</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">

                            {foundation_Select_data.map((foundation_data,index)=>(
                              <div className='d-flex' key={index}>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${index}`}
                                  label={foundation_data.label}
                                  value={index} // Use index as the value for filtering
                  checked={filters.foundation.includes(index)} // Check if the checkbox is selected
                  onChange={() => handleCheckboxChange(index, 'foundation')} // Handle checkbox change
                                />
                                <span className='all-tot'></span>
                              </div>
                          
                            ))}
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="13">
                    <Accordion.Header><img src={foundation} />Special Features</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">

                            {special_features_Select_data.map((special_data,index)=>(
                              <div className='d-flex' key={index}>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${index}`}
                                  label={special_data.label}
                                  value={index} // Use index as the value for filtering
                  checked={filters.special_features.includes(index)} // Check if the checkbox is selected
                  onChange={() => handleCheckboxChange(index, 'special_features')} // Handle checkbox change
                                />
                                <span className='all-tot'></span>
                              </div>
                          
                            ))}
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="14">
                    <Accordion.Header><img src={foundation} />Exterior Walls</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">

                            {exterior_walls_Select_data.map((exterior_data,index)=>(
                              <div className='d-flex' key={index}>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${index}`}
                                  label={exterior_data.label}
                                  value={index} // Use index as the value for filtering
                  checked={filters.exterior_walls.includes(index)} // Check if the checkbox is selected
                  onChange={() => handleCheckboxChange(index, 'exterior_walls')} // Handle checkbox change
                                />
                                <span className='all-tot'></span>
                              </div>
                          
                            ))}
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="15">
                    <Accordion.Header><img src={foundation} />Collection</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">

                            {collections_Select_data.map((collection_data,index)=>(
                              <div className='d-flex' key={index}>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${index}`}
                                  label={collection_data.label}
                                  value={index} // Use index as the value for filtering
                  checked={filters.collections.includes(index)} // Check if the checkbox is selected
                  onChange={() => handleCheckboxChange(index, 'collections')} // Handle checkbox change
                                />
                                <span className='all-tot'></span>
                              </div>
                          
                            ))}
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="16">
                    <Accordion.Header><img src={foundation} />Lot features</Accordion.Header>
                    <Accordion.Body>
                      <div className='all-options'>
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`default-${type}`} className="mb-3">

                            {lot_features_Select_data.map((lot_data,index)=>(
                              <div className='d-flex' key={index}>
                                <Form.Check // prettier-ignore
                                  type={type}
                                  id={`default-${index}`}
                                  label={lot_data.label}
                                  value={index} // Use index as the value for filtering
                  checked={filters.lot_features.includes(index)} // Check if the checkbox is selected
                  onChange={() => handleCheckboxChange(index, 'lot_features')} // Handle checkbox change
                                />
                                <span className='all-tot'></span>
                              </div>
                          
                            ))}
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
           
           
                          
           
           
           {/* ---------------------------------------------------------------main lisitng div------------------------------------------------------------------------------------- */}
            <div className='col-lg-8'>
              <div className='farmplans'>
                {/* <span>1,854 plans found!</span> */}
                <h4>Plans</h4>
              </div>
              <div className='plans-farms'>
                {
                  Array.isArray(propertyList?.data) && propertyList?.data?.length > 0 ?
                    propertyList?.data?.map((res, index) => {
                      return <>
                        <div className="wrap" key={res?._id}>
                            <div className="img-h pt-0">
                              <img src={res?.main_images?.length > 0 ? res?.main_images[0] : imghouse1} style={{ height: '250px', width: '280px' }} />
                              <div className="sociali-btn">
                                <div className="youtube">
                                  <img src={youtubr} />
                                </div>
                                <div onClick={() => handleAddToFavourites(res?._id)} className="fav">
                                  {/* <img src={fav} />
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 21.35L10.55 20.03C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5C22 12.27 18.6 15.36 13.45 20.03L12 21.35Z" fill="#F46530"/>
      </svg> */}
            
            {res.is_liked ? (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 21.35L10.55 20.03C5.4 15.36 2 12.27 2 8.5C2 5.41 4.42 3 7.5 3C9.24 3 10.91 3.81 12 5.08C13.09 3.81 14.76 3 16.5 3C19.58 3 22 5.41 22 8.5C22 12.27 18.6 15.36 13.45 20.03L12 21.35Z" fill="#F46530"/>
  </svg>
) : (
  <img src={fav} alt="Favorite icon" />
)}

                                </div>
                              </div>
                            </div>
                            <Link to={`/property/${res?._id}`} className="custom-link">

                            <div className="ex-tag">
                              <img src={exclusive} />
                            </div>
                            <div className="img-content">
                              <div className="h-details">
                                <div className="area">
                                  <strong>{res?.sq_ft || '0'}</strong>
                                  <p className="mb-0">Sq. Ft.</p>
                                </div>
                                <hr></hr>
                                <div className="area">
                                  <strong>{res?.beds || '0'}</strong>
                                  <p className="mb-0">Bed</p>
                                </div>
                                <hr></hr>
                                <div className="area">
                                  <strong>{res?.baths || '0'}</strong>
                                  <p className="mb-0">Bath</p>
                                </div>
                                <hr></hr>
                                <div className="area">
                                  <strong>{res?.story || '0'}</strong>
                                  <p className="mb-0">Story</p>
                                </div>
                                <hr></hr>
                                <div className="area">
                                  <strong>{res?.cars || '0'}</strong>
                                  <p className="mb-0">Cars</p>
                                </div>
                              </div>
                              <hr></hr>
                              <div className="d-flex justify-content-between">
                                <div className="pln-no">
                                  <span>Plan Number</span>
                                  <h2>{res?.plan_number ? `#${res?.plan_number}` : 'N/A'}</h2>
                                </div>
                                <div className="pln-no">
                                  <span>Starts From</span>
                                  <h2 className="price">${res?.price || '0'}</h2>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </>
                    })
                    : <p className="text-center">No Data Found</p>
                }
              </div>
            </div>
              <Pagination     activePage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={propertyList?.count}  // Assuming you get this from the API
          onPageChange={handlePageChange}  ></Pagination>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PropertyList;
