import React, { useEffect, useState } from "react";
import Layout from './Layout'
import checkoutcart from "../assets/images/checkoutcart.png"
import { applyPayment, applyPromoCode, deleteCartItem, getCart, getPromoCodes } from "../service/apiService";
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { plan_style_select_data } from "../constant/constant";
import { toast } from "react-toastify";
const Checkout = () => {
    const [paymentMode, setPaymentMode] = useState('');
    const [promoCode, setPromoCode] = useState('');
const navigate = useNavigate()
    const location = useLocation();
    const [discountedPrice, setDiscountedPrice] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [clientSecret, setClientSecret] = useState('');


    const { cartId } = location.state || {};
    console.log("Cart ID:", cartId);
    const [promoCodes, setPromoCodes] = useState([]);
    const handlePaymentModeChange = (e) => {
        setPaymentMode(e.target.value);
    };
    
    const handlePromoCodeChange = async (e) => {
        const selectedPromo = e.target.value;
        setPromoCode(selectedPromo);
        console.log("Promo Code:", selectedPromo)
        if (selectedPromo) {
            try {
                const response = await applyPromoCode(
                  cartId,
                    selectedPromo
                );
                
                console.log("Promo Code:respsoen", response)
                if (response.status ==200) {
                    setDiscountedPrice(response?.data?.data?.to_pay );
                    toast.success("Promo code applied successfully!");
                } else {
                    toast.error(response?.data?.message || "Failed to apply promo code.");
                }
            } catch (error) {
                console.error("Error applying promo code:", error);
                toast.error("Error applying promo code.");
            }
        } else {
            setDiscountedPrice(cartId?.property_id?.price);
        }
    };
    
    const planStyleLabel =
    plan_style_select_data?.find((style) => style?.value == cartId?.property_id?.plan_style_id)?.label || 'N/A';

    useEffect(() => {
        const fetchPromoCodes = async () => {
            try {
                const response = await getPromoCodes(); // Assume this API returns a list of promo codes
                setPromoCodes(response?.data || []);
            } catch (error) {
                console.error("Failed to fetch promo codes:", error);
            }
        };

        fetchPromoCodes();
    }, []);



    // const handleCheckout = async () => {
    //     try {
    //         // Assuming `data` contains the payment data, including `client_secret_id`
    //         const  client_secret_id  = 'pi_3QRC15C5m2qCeXrM0swDDiVb_secret_kMl0O6eq67lRNFkQmCFCBNhZC';
    
    //         if (client_secret_id) {
    //             // Redirect to Stripe Checkout
    //             window.location.href = `/checkout/stripe?client_secret=${client_secret_id}`;
    //         } else {
    //             alert('Payment intent could not be created.');
    //         }
    //     } catch (error) {
    //         console.error('Error during checkout:', error.response?.data || error.message);
    //         alert('Failed to create order.');
    //     }
    // };
    
    const [isLoading, setIsLoading] = useState(false);

    const handlePaymentSubmit = async () => {
        if (!paymentMode) {
            toast.error("Please select a payment mode before proceeding.");
            return; // Exit the function if payment mode is not selected
        }
        setIsLoading(true);

        const paymentData = {
            payment_mode: paymentMode,  // 1 for COD, 2 for Stripe
            cart_id: cartId?._id,
            promocode_id: promoCode || null  // Optional, depends on promo code usage
        };
    
        try {
            const response = await applyPayment(paymentData);
            if(paymentMode ===1){
                deleteCartItem(cartId._id)
                navigate('/cart')
            }else{
                
                navigate('/testcheckout', { state: { clientSecretId: response?.data?.data?.client_secret_id } });
            }
            console.log(response)
            
        } catch (error) {
            console.error("Error during payment:", error);
            toast.success(error?.message,'order failed');
        } finally {
            // Re-enable the button after the request is completed
            setIsLoading(false);
        }
    };
    return (
        <>
            <Layout>
                <div className='container'>
                    <div className="top-heading mt-5">
                        <h2>Checkout</h2>
                    </div>
                </div>
                <section className='carts '>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-9 mx-auto'>
                                <div className='review-wrap check-wrap'>
                                    <div className="wraps">
                                        <div className='d-flex top-h'>
                                            <strong>Item 1</strong>
                                            {/* <span>Edit</span> */}
                                        </div>
                                        <div className='d-flex'>
                                            <img   style={{ width: '100%', height: '250px' }}  src={cartId?.property_id?.main_images[0]} />
                                            <p>Plan {cartId?.property_id?.plan_number} {cartId?.property_id?.beds}-Bed {`${planStyleLabel} House Plan`}   {cartId?.property_id?.sq_ft} Square Feet  {cartId?.property_id?.cars}-Car Garage</p>
                                        </div>
                                        <hr className='w-100'></hr>
                                        <div className='email-details'>
                                            <div className='d-flex justify-content-between'>
                                                <strong>Email To</strong>
                                                <span className='edit-link'>Edit</span>
                                            </div>
                                            <div className='d-flex justify-content-between '>

                                                <span>Name:</span>
                                                <strong>ABC</strong>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <span>Email Address</span>
                                                <strong>ABC</strong>

                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <span>Conact Number </span>
                                                <strong>ABC</strong>

                                            </div>
                                            <div className='d-flex justify-content-between'>

                                                <span>Address</span>
                                                <strong> ABC</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='cart-content check-acrt'>

                                        <hr className='divider'></hr>
                                        <div className='subtotal check-total'>
                                            <div className='plan'>
                                                <h5>Plan {cartId?.property_id?.plan_number} </h5>
                                                <div className='pdf'>
                                                    <span>PDF - Single - Build</span>
                                                    <strong>$25</strong>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            {/* <div className='plan'>
                                                <h5>Plan 135215GRA  </h5>
                                                <div className='pdf'>
                                                    <span>PDF - Single - Build</span>
                                                    <strong>$25</strong>
                                                </div>
                                            </div> */}
                                            <hr className='mt-5'></hr>
                                            <div className='cart-total'>
                                                <div className='tax'>
                                                    <span>Tax</span>
                                                    <strong>$25</strong>
                                                </div>
                                                <div className='tax'>
                                                    <span>Subtotal </span>
                                                    <strong>$25</strong>
                                                </div>
                                                <div className='tax final-total'>
                                                    <span>Total </span>
                                                    <strong>${cartId?.property_id?.price}</strong>
                                                    {/* <strong>${discountedPrice !== null ? discountedPrice : cartId?.property_id?.price}</strong> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className='order-summary'>
                                    <h4>Order Summary</h4>
                                    <hr className='w-100'></hr>
                                    <div className='order-total'>
                                        <div className='d-flex justify-content-between mb-3'>
                                            <span>Tax</span>
                                            <strong>$25</strong>
                                        </div>
                                        
                                        <div className='d-flex total-sum justify-content-between'>
                                            <span>Total</span>
                                            {/* <strong>${cartId?.property_id?.price}</strong> */}
                                            <strong>${discountedPrice !== null ? discountedPrice : cartId?.property_id?.price}</strong>

                                        </div>
                                        <form className='pt-5'>
                                            {/* <div class="mb-3 mt-4">
                                                <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Apply Coupon Code" />
                                            </div> */}
                                                {/* Promocode Dropdown */}
                                        <div className="mb-3">
                                            <label htmlFor="promoCodeSelect">Select Promocode</label>
                                            <select
                                                className="form-select"
                                                id="promoCodeSelect"
                                                value={promoCode}
                                                onChange={handlePromoCodeChange}
                                            >
                                                <option value="">No PromoCode</option>
                                                {promoCodes.map((promo) => (
                                                    <option key={promo.id} value={promo._id}>
                                                        {promo.code} - {promo.discount}
                                                        {promo.discount_type === 1 ? "%" : " "}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
    {/* Payment Mode Selection */}
    <div className="mb-3">
        <label>Select Payment Mode</label>
        <div className="form-check">
            <input
                type="radio"
                className="form-check-input"
                id="cod"
                name="payment_mode"
                value="1"  // COD
                checked={paymentMode === '1'}
                onChange={handlePaymentModeChange}
            />
            <label className="form-check-label" htmlFor="cod">
                Cash on Delivery (COD)
            </label>
        </div>
        <div className="form-check">
            <input
                type="radio"
                className="form-check-input"
                id="stripe"
                name="payment_mode"
                value="2"  // Stripe Payment (Online)
                checked={paymentMode === '2'}
                onChange={handlePaymentModeChange}
            />
            <label className="form-check-label" htmlFor="stripe">
                Online Payment (Stripe)
            </label>
        </div>
    </div>

                                            <button  disabled={isLoading} onClick={handlePaymentSubmit} type="button" class="btn btn-secondary w-100 search">Proceed To Payment <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.94766 6.00043L0.347656 1.40043L1.40141 0.34668L7.05516 6.00043L1.40141 11.6542L0.347656 10.6004L4.94766 6.00043Z" fill="white" />
                                            </svg></button>
                                            {/* <button
                                        type="button"
                                        className="btn btn-primary w-100"
                                        onClick={handleCheckout}
                                    >
                                        Pay Now
                                    </button> */}


                                    
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </Layout>
        </>
    )
}

export default Checkout
