// import { createSlice } from "@reduxjs/toolkit";
// import { detailsPropertyAction, listPropertyAction } from "../actions/PropertyAction";
// const initialState = {
//     propertyList: [],
//     propertyDetails: {},
//     loading: false, 
//     error: null,
// }

// const propertySlice = createSlice({
//     name: 'property',
//     initialState,
//     reducers: {},
//     extraReducers: (builder) => {
//         builder
//             .addCase(listPropertyAction.fulfilled, (state, action) => {
//                 state.loading = false;
//                 state.propertyList = action?.payload?.data;
//             })
//             .addCase(detailsPropertyAction.fulfilled, (state, action) => {
//                 state.loading = false;
//                 state.propertyDetails = action?.payload?.data;
//             })
            
//     },
// });

// export default propertySlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { detailsPropertyAction, listPropertyAction } from "../actions/PropertyAction";
const initialState = {
    propertyList: [],
    propertyDetails: {},
    loading: false, 
    error: null,
}

const propertySlice = createSlice({
    name: 'property',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
           .addCase(detailsPropertyAction.pending, (state) => {
                state.loading = true; // Set loading to true when the API call starts
                state.error = null;   // Reset error
            })
            .addCase(listPropertyAction.fulfilled, (state, action) => {
                state.loading = false;
                state.propertyList = action?.payload?.data;
            })
            .addCase(detailsPropertyAction.fulfilled, (state, action) => {
                state.loading = false; // Set loading to false when data is fetched
                state.propertyDetails = action?.payload?.data;
            })
            .addCase(detailsPropertyAction.rejected, (state, action) => {
                state.loading = false; // Set loading to false on error
                state.error = action.payload; // Capture the error
            });
            
    },
});

export default propertySlice.reducer;