import React from 'react'
import Layout from './Layout'
import costbuild from '../assets/images/costbuild.png'
// import './style.css'

const CostToBuild = () => {
  return (
    <>
    <Layout>
     <div className='m-5 mobil'>
     <section className='costbuild'>
        <div className='container'>
            <div className='text-center'>
            <h2>Cost-to-Build Report</h2>
            <img src={costbuild} />
            <button type="button" class="btn btn-outline-primary phno my-5"> View Sample Cost-to-Build Report</button>
           
            </div>
            <div className='houses-plans'>
                <h4>Exclusive Cost-to-Build Report Available On Most House Plans</h4>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='specific'>
                            <strong>Specific</strong>
                            <p>Cost-to-Build Reports provide an estimate of the cost to build a specific home, in a specific location. This estimate considers the location, the home's complexity, and its features rather than relying on generic cost-per-square-foot data.</p>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                    <div className='specific'>
                            <strong>Customizable</strong>
                            <p>Unlike other cost estimation services, our Cost-to-Build Reports come with access to the tool that we use to create them, giving you the ability to change settings to reflect changes you want to make to the home plan. You can then regenerate the report to learn how the change in settings will affect your cost to build.</p>
                        </div>
                        </div>
                        <div className='col-lg-4'>
                        <div className='specific'>
                            <strong>Reliable</strong>
                            <p>The reports are generated using tools provided by Verisk’s data, an industry leader for estimating solutions and building cost data. Material and labor pricing information is updated monthly to ensure it reflects current market trends. Many of the nation's leading insurance companies use this valuation tool to determine home building costs.</p>
                        </div>
                        </div>
                        <div className='col-lg-6'>
                        <div className='specific'>
                            <strong>Savings</strong>
                            <p>You can purchase bundles of three Cost-to-Build Reports at a discounted price (usually $44.95 each, a bundle of three is $124.95), which can help save money if comparing plans. If you buy a plan within six months from when a Cost-to-Build Report was purchased for that plan, we will credit the Cost-to-Build Report towards your plan purchase.</p>
                        </div>
                        </div>
                        <div className='col-lg-6'>
                        <div className='specific'>
                            <strong>Fast and Convenient</strong>
                            <p>Our Cost-to-Build Reports are delivered digitally and are available to download through your account on our website within 1 to 2 business days.
Overall, our Cost-to-Build Report provides a reliable and accurate cost estimation for building a home and can help you make informed decisions about your future home construction project.</p>
                        </div>
                        </div>
                </div>
            </div>
        </div>
    </section>     </div>
    </Layout>
    </>
  

  
  )
}

export default CostToBuild