import React from 'react'
// import "./style.css"
import Layout from '../Layout'

function Modification_services() {
  return (
    <Layout>
  <section className='modification'>
      <div className='container'>
          <h2>How To Request Modification Services</h2>
          <div className='other-contents'>
              <p>Architectural Designs is here to help if you are needing plan modification services. Do you need to change the foundation type? Is your property dictating a garage orientation change from a front load entry to a side entry version? Are you wanting to customize specific areas for your family’s lifestyle? These changes and many others are possible. Follow the instructions below on how to submit a modification request. A free modification quote and timeline to complete the work will be emailed to you within 1-3 business days.</p>

          </div>
          <div className='other-contents'>
              <h4>How to submit your Modification Request:</h4>
              <p>Go to the plan you would like to modify and click the “Modify this Plan” button. Provide your contact information, detail the changes you would like made and upload any supporting images. You will receive a confirmation after clicking the Submit button. A request can also be submitted by faxing us at 203.761.8600.</p>
              
          </div>
          <div className='other-contents'>
              <h4>Ask Questions!</h4>
              <p>A quote outlining the cost to make the modifications and the time needed to make them will be emailed to you within 1-3 business days. If you have questions, we are here to answer them so please do not hesitate to contact us by email or phone.</p>
              
          </div>
          <div className='other-contents'>
              <h4>Ready to get started?</h4>
              <p>Follow the instructions in the quote to get your modification project underway. If your preference is for one of our seasoned Customer Service team to take your order by phone, please call us Monday-Friday, 9-5EST at +234 8109824535.</p>
              
          </div>
          <div className='other-contents'>
              <h4>What happens next?</h4>
              <p>An email providing designer contact information will follow your order confirmation. Keep this important email for your records.</p>
              
          </div>
      </div>
  </section>
  </Layout>
  )
}

export default Modification_services