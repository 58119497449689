import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
// import './fonts/stylesheet.css';
import './assets/css/style.css';
import { Provider } from "react-redux";
import { Toaster } from 'react-hot-toast';
import store from './redux/store';
import "pure-react-carousel/dist/react-carousel.es.css";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_test_51QD3gqC5m2qCeXrMOF59t9jMVidOGB4ovsL3uPBveHtA7ESaP9CEoNppOZIAv4Gx7PnTSw1iBDbxr49sPBOW4Kf700ERgvLKJz');
const options = 
  'pi_3QRC15C5m2qCeXrM0swDDiVb_secret_kMl0O6eq67lRNFkQmCFCBNhZC';  // Pass the client secret to Elements

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Toaster position="top-right" reverseOrder={false} />
    <Elements stripe={stripePromise} >

    <Provider store={store}>
      <App />
    </Provider>
    </Elements>


  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
