import React from 'react'
import { Link } from 'react-router-dom'
import architect from '../../assets/images/architect.png'
import architect2 from '../../assets/images/architect2.png'
import architect3 from '../../assets/images/architect3.png'
import architect4 from '../../assets/images/architect4.png'
import roofplan from '../../assets/images/roofplan.png'
import electrical from '../../assets/images/electrical.png'
import builcode from '../../assets/images/builcode.png'
import engineer from '../../assets/images/engineer.png'
import mirrors from '../../assets/images/mirrors.svg'
import material from '../../assets/images/material.svg'
import reverses from '../../assets/images/reverses.svg'
import Layout from '../Layout'
// import './style.css'

function Included_our_plans() {
  return (
    <Layout>

  <section className='includeplans'>
      <div className='container'>
          <h3>What Is Included In Our Plans?</h3>
          <div className='aboutpackage'>
              <div className='coverbg'>
              <p>We work with hundreds of designers and architects who work in a variety of styles.  It is important to note. Their plan packages may contain different details.</p>
              <p>We strongly encourage you to <Link to="/contactus">contact us</Link> for the contents of any plan you may be interested in purchasing.</p>
          <p>Below are examples of sheets that may be included in plan sets - please be aware that not every plan includes every one of these pages:</p>
         
              </div>
              <div className='archietect'>
            <img src={architect} />
            <div>
            <h4>Foundation Plan  </h4>
            <p>shows the suggested locations for fixtures and outlets. Please contact us to see if an electrical plan is included in the plan set.</p>
           
            </div>
          <img src={architect2} />
          <div>
            <h4>Floor Plan </h4>
            <p>shows the suggested locations for fixtures and outlets. Please contact us to see if an electrical plan is included in the plan set.</p>
           
            </div>
            <img src={architect3} />
            <div>
            <h4>Exterior Elevation </h4>
            <p>shows the suggested locations for fixtures and outlets. Please contact us to see if an electrical plan is included in the plan set.</p>
           
            </div>
            <img src={architect4} />
            <div>
            <h4>Building Cross Section</h4>
            <p>shows the suggested locations for fixtures and outlets. Please contact us to see if an electrical plan is included in the plan set.</p>
           
            </div>
            <img src={roofplan} />
            <div>
            <h4>Roof Plans</h4>
            <p>shows the suggested locations for fixtures and outlets. Please contact us to see if an electrical plan is included in the plan set.</p>
           
            </div>
            <img src={electrical} />
            <div>
            <h4>Electrical Plan (when available) </h4>
            <p>shows the suggested locations for fixtures and outlets. Please contact us to see if an electrical plan is included in the plan set.</p>
           
            </div>
          </div>
          <div className='row mt-5 buildcoding'>
              <div className='col-lg-6 '>
                    <div className='buildcode'>
                        <img src={builcode} />
                        <div className='buil-content'>
                            <h5>Building Code</h5>
                            <p>Because of the wide range of requirements throughout states and municipalities, it is possible that the plans may not have all of the information required by your building department. If this is the case, we recommend hiring a local design professional or engineer who can assist bringing the plans up to your local building code.</p>
                        </div>
                    </div>
              </div>
              <div className='col-lg-6 '>
              <div className='buildcode'>
                        <img src={engineer} />
                        <div className='buil-content'>
                            <h5>Engineering</h5>
                            <p>Some states require that a licensed architect or engineer review and stamp the plans. If this is the case where you will be building, a local professional will be able to assist you.</p>
                        </div>
                    </div>
                </div>
          </div>
         <div className='imp-extras'>
         <h4>Important Extras: Sold separately</h4>
         </div>
          <div className='row'>
              <div className='col-lg-4'>
                    <div className='reverse'>
                        <img src={reverses} />
                        <h4>Readable Reverse Plans</h4>
                        <p>The layout is reversed and all the writing is corrected so you can read the writing. With this option, all of your sets will appear the way you want to build the home.</p>
                    </div>
              </div>
              <div className='col-lg-4'>
              <div className='reverse'>
                        <img src={mirrors} />
                        <h4>Mirror Reverse Plans</h4>
                        <p>The lettering and dimensions will read backwards, so you will need to order at least one set non-reversed as a reference copy.</p>
                    </div>
                </div>
                <div className='col-lg-4'>
                <div className='reverse'>
                        <img src={material} />
                        <h4>Itemized List of Materials</h4>
                        <p>Includes the quantity, type and size of materials needed to build your home.</p>
                    </div>
                </div>
          </div>
          </div>
      </div>
  </section>
  </Layout>

  )
}

export default Included_our_plans