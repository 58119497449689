// // src/components/Spinner.js
// import React from 'react';
// import Pagination from 'react-bootstrap/Pagination';

// const PaginationComponent = () => {
//     return (
//         <div>
//             <div className='paging'>
//                 <Pagination>
//                     <Pagination.First />
//                     <Pagination.Prev />
//                     <Pagination.Item>{1}</Pagination.Item>
//                     <Pagination.Ellipsis />

//                     <Pagination.Item>{10}</Pagination.Item>
//                     <Pagination.Item>{11}</Pagination.Item>
//                     <Pagination.Item active>{12}</Pagination.Item>
//                     <Pagination.Item>{13}</Pagination.Item>
//                     <Pagination.Item disabled>{14}</Pagination.Item>

//                     <Pagination.Ellipsis />
//                     <Pagination.Item>{20}</Pagination.Item>
//                     <Pagination.Next />
//                     <Pagination.Last />
//                 </Pagination>
//             </div>
//         </div>
//     );
// };

// export default PaginationComponent;




import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const PaginationComponent = ({ activePage, itemsPerPage, totalItems, onPageChange }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage)-1; // Calculate total pages
console.log('ttttttttttttt',totalPages)
    const handlePageClick = (pageNumber) => {
        onPageChange(pageNumber);
    };

    const renderPaginationItems = () => {
        let items = [];
        for (let number = 0; number <= totalPages; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === activePage}
                    onClick={() => handlePageClick(number)}
                >
                    {number}
                </Pagination.Item>
            );
        }
        return items;
    };

    return (
        <div className='paging'>
            <Pagination>
                <Pagination.First onClick={() => handlePageClick(1)} disabled={activePage === 0} />
                <Pagination.Prev onClick={() => handlePageClick(activePage - 1)} disabled={activePage === 0} />

                {renderPaginationItems()}

                <Pagination.Next onClick={() => handlePageClick(activePage + 1)} disabled={activePage === totalPages} />
                <Pagination.Last onClick={() => handlePageClick(totalPages)} disabled={activePage === totalPages} />
            </Pagination>
        </div>
    );
};

export default PaginationComponent;
