// import React from 'react'
// import Layout from './Layout'
// import emailcon from '../assets/images/email-con.svg'
// import phonenosvg from '../assets/images/phonenosvg.svg'
// import fax from '../assets/images/fax.svg'

// const ContactUs = () => {
//   return (
//     <Layout>
//       <section className='contact'>
//         <div className='top-heading'>
//           <h2>Contact Us</h2>
//         </div>
//         <div className='container'>
//           <div className='row'>
//             <div className='col-lg-6'>
//               <div className='contact-details'>
//                 <h3>Questions on a plan? We’re here to help. Fill out the form below and we’ll get you answers.</h3>
//                 <div className='email'>
//                   <img src={emailcon} />
//                   <div className='ms-3'>
//                     <span>Email:</span>
//                     <p>hello@hoadesk.com</p>
//                   </div>
//                 </div>
//                 <div className='email'>
//                   <img src={phonenosvg} />
//                   <div className='ms-3'>
//                     <span>Call Center available 24/7</span>
//                     <p>75021000555</p>
//                   </div>
//                 </div>
//                 <div className='email'>
//                   <img src={phonenosvg} />
//                   <div className='ms-3'>
//                     <span>International Phone</span>
//                     <p>75021000555</p>
//                   </div>
//                 </div>
//                 <div className='email'>
//                   <img src={fax} />
//                   <div className='ms-3'>
//                     <span>Fax</span>
//                     <p>75021000555</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className='col-lg-6'>
//               <div className='contact-form'>
//                 <div class="form-floating mb-3">
//                   <input type="text" class="form-control" id="floatingInput" placeholder="Requester Name" />
//                   <label for="floatingInput">Requester Name</label>
//                 </div>
//                 <div class="form-floating mb-3">
//                   <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" />
//                   <label for="floatingInput">Email address</label>
//                 </div>
//                 <div class="form-floating mb-3">
//                   <input type="text" class="form-control" id="floatingInput" placeholder="Subject" />
//                   <label for="floatingInput">Subject</label>
//                 </div>
//                 <div class="form-floating">
//                   <textarea class="form-control" placeholder="Leave a comment here" id="floatingTextarea"></textarea>
//                   <label for="floatingTextarea">Comments</label>
//                 </div>
//                 <button type="button" class="btn btn-secondary search">Submit</button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </Layout>
//   )
// }

// export default ContactUs



import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submitContactForm, resetContactForm } from '../redux/reducers/contactUsSlice';
import Layout from './Layout';
import emailcon from '../assets/images/email-con.svg';
import phonenosvg from '../assets/images/phonenosvg.svg';
import fax from '../assets/images/fax.svg';
import { toast } from 'react-toastify';

const ContactUs = () => {
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector((state) => state.contactUs);

  useEffect(() => {
    if (success) {
      toast.success('Your message was sent successfully!');
      dispatch(resetContactForm()); // Reset the form state after showing success
    }

    if (error) {
      toast.error(`There was an error: ${error}`);
    }
  }, [success, error, dispatch]);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    comment: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation (simple example)
    if (!formData.name || !formData.email || !formData.subject || !formData.comment) {
      toast.error('All fields are required!');
      return;
    }

    // Dispatch the API action
    dispatch(submitContactForm(formData));
  };

  return (
    <Layout>
      <section className="contact">
        <div className="top-heading">
          <h2>Contact Us</h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
            <div className='contact-details'>
                    <h3>Questions on a plan? We’re here to help. Fill out the form below and we’ll get you answers.</h3>
                    <div className='email'>
                        <img src={emailcon} />
                        <div className='ms-3'>
                            <span>Email:</span>
                            {/* <p>hello@hoadesk.com</p> */}
                            <p>

                            <a href="mailto: rholzsquad.studio@gmail.com" >rholzsquad.studio@gmail.com</a>
                            </p>

                        </div>
                    </div>
                    <div className='email'>
                        <img src={phonenosvg} />
                        <div className='ms-3'>
                            <span>Call Center available 24/7</span>
                            <p><a href="tel: +234 8109824535" >+234 8109824535</a>
</p>
                        </div>
                    </div>
                    <div className='email'>
                        <img src={phonenosvg} />
                        <div className='ms-3'>
                            <span>International Phone</span>
                            <p><a href="tel: +234 8109824535" >+234 8109824535</a>
</p>
                        </div>
                    </div>
                    <div className='email'>
                        <img src={fax} />
                        <div className='ms-3'>
                            <span>Fax</span>
                            <p>+234 8109824535</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-6">
              <div className="contact-form">
                <form onSubmit={handleSubmit}>
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Requester Name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    <label htmlFor="name">Requester Name</label>
                  </div>

                  <div className="form-floating mb-3">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      placeholder="name@example.com"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <label htmlFor="email">Email address</label>
                  </div>

                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      className="form-control"
                      id="subject"
                      name="subject"
                      placeholder="Subject"
                      value={formData.subject}
                      onChange={handleChange}
                    />
                    <label htmlFor="subject">Subject</label>
                  </div>

                  <div className="form-floating">
                    <textarea
                      className="form-control"
                      id="comment"
                      name="comment"
                      placeholder="Leave a comment here"
                      value={formData.comment}
                      onChange={handleChange}
                    ></textarea>
                    <label htmlFor="comment">Comments</label>
                  </div>

                  <button type="submit" className="btn btn-secondary search mt-3">
                    {loading ? 'Submitting...' : 'Submit'}
                  </button>
                </form>

                {success && <p className="text-success mt-3">Your message was sent successfully!</p>}
                {error && <p className="text-danger mt-3">There was an error: {error}</p>}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContactUs;
