import blog_img_details from "../assets/images/blog-img-details.png"
import link from "../assets/images/link.svg"
import twitter from "../assets/images/twitter.svg"
import ri_instagram_fill from "../assets/images/ri_instagram-fill.svg"
import mdi_linkedin from "../assets/images/mdi_linkedin.svg"
import ic_baseline_facebook from "../assets/images/ic_baseline-facebook.svg"
import bg_content_img from "../assets/images/bg-content-img.png"
import { Link } from 'react-router-dom'
import blogimg from "../assets/images/blogimg.png"
import Layout from './Layout'

const BlogDetails = () => {
  return (
    <>
      <Layout>
      <section className='blog-details'>
       <div className='container'>
           <div className='img-b'>
                <img src={blog_img_details} />
           </div>
           <div className='blog-title'>
               <h4>Modern Farmhouse Plan 69715AM Comes to Life in Virginia</h4>
               <span>Alec Whitten • 17 Jan 2022</span>
           </div>
           <div className='share-icons'>
               <p className='mb-0'>Share:</p>
              <div className='d-flex'>
              <img src={link} />
                <img src={twitter} />
                <img src={ri_instagram_fill} />
                <img src={mdi_linkedin} />
                <img src={ic_baseline_facebook} />
              </div>
           </div>
           <p>In the ever-evolving landscape of the digital world, having a strong online presence is crucial for the success of any business or blog. Search Engine Optimization (SEO) plays a pivotal role in ensuring that your website not only gets noticed but also ranks high on search engine result pages. In this article, we will explore five key strategies to boost your website's SEO and enhance its visibility.</p>
            <div className='row blog-inner'>
                <div className='col-lg-6'>
                   <div className='p-2'>
                   <img src={bg_content_img} />
                   </div>
                </div>
                <div className='col-lg-6'>
               <div className='p-2'>
               <img src={bg_content_img} />
               </div>
                    </div>
            </div>
            <div className='inner-content'>
                <strong>House and all:</strong>
                <p>Keywords are the foundation of SEO. Understanding the phrases and terms your target audience is using to search for content is essential. Conduct thorough keyword research to identify relevant and high-traffic keywords related to your niche. Once you have a list of target keywords, strategically integrate them into your website's content, meta titles, meta descriptions, and headers.
                    <br></br>
                    Remember, the goal is not just to stuff keywords indiscriminately but to create high-quality, informative content that naturally incorporates these keywords. Search engines reward websites that provide valuable content to users. Utilize tools like Google Keyword Planner, SEMrush, or Ahrefs to identify and analyze potential keywords for your content
                </p>
            </div>
            <h4>Related Blogs</h4>
            <div className='row'>
               <div className='col-lg-4'>
                    <div className='blog-cover'>
                       <div>
                       <img src={blogimg} />
                     <div className='bg-content'>
                            <div className='dte'><p>Alec Whitten <span>• 17 Jan 2022</span></p></div>
                     
                        <strong>Bill Walsh leadership lessons</strong>
                        <p>Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Like to know ......</p>
                    <div className='read-more-blg'>
                        <Link to="">Read More <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.998535 8.00037L15.9692 7.99936" stroke="#F46530" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.88672 1.91864L15.9681 8.00006L9.8859 14.0823" stroke="#F46530" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></Link>
                    </div>
                     </div>
                    </div>
                    </div>
               </div>
               <div className='col-lg-4'>
                    <div className='blog-cover'>
                       <div>
                       <img src={blogimg} />
                     <div className='bg-content'>
                            <div className='dte'><p>Alec Whitten <span>• 17 Jan 2022</span></p></div>
                     
                        <strong>Bill Walsh leadership lessons</strong>
                        <p>Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Like to know ......</p>
                    <div className='read-more-blg'>
                        <Link to="">Read More <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.998535 8.00037L15.9692 7.99936" stroke="#F46530" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.88672 1.91864L15.9681 8.00006L9.8859 14.0823" stroke="#F46530" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></Link>
                    </div>
                     </div>
                    </div>
                    </div>
               </div>
               <div className='col-lg-4'>
                    <div className='blog-cover'>
                       <div>
                       <img src={blogimg} />
                     <div className='bg-content'>
                            <div className='dte'><p>Alec Whitten <span>• 17 Jan 2022</span></p></div>
                     
                        <strong>Bill Walsh leadership lessons</strong>
                        <p>Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Like to know ......</p>
                    <div className='read-more-blg'>
                        <Link to="">Read More <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.998535 8.00037L15.9692 7.99936" stroke="#F46530" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.88672 1.91864L15.9681 8.00006L9.8859 14.0823" stroke="#F46530" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></Link>
                    </div>
                     </div>
                    </div>
                    </div>
               </div>
             
           </div>
       </div>
   </section>
      </Layout>
    </>
  )
}

export default BlogDetails
