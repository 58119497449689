import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const ShareModal = ({ show, handleClose, url }: any) => {
  const handleCopyLink = () => {
    navigator.clipboard.writeText(url);
    alert("Link copied to clipboard!"); // Replace this with a Toastr if preferred
  };

  const sharingOptions = [
    { name: "Facebook", url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}` },
    { name: "Twitter", url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}` },
    {
        name: "Email",
        url: `mailto:?subject=Check out this page&body=Here's the link: ${encodeURIComponent(url)}`
      }  ];

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Share This Page</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Share this page using one of the options below:</p>
        <div className="share-options">
          {sharingOptions.map((option) => (
            <Button
              key={option.name}
              variant="outline-primary"
              onClick={() => window.open(option.url, "_blank")}
              style={{ margin: "5px" }}
            >
              {option.name}
            </Button>
          ))}
          <Button variant="outline-secondary" onClick={handleCopyLink} style={{ margin: "5px" }}>
            Copy Link
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShareModal;
