import React from "react";
// import './style.css'
import Layout from "../Layout";

function Designers() {
  return (
    <Layout>
    <section className="designers">
      <div className="container">
        <h3>Designers</h3>
        <div className="row">
          <div className="col-lg-4">
            <div className="designer-about">
              <p>
                Architectural Designs is always interested in hearing from new,
                talented designers and we appreciate your interest in working
                with us. As our tagline states, we have been "selling quality
                house plans for generations." We are family-owned, care deeply
                about our design partners and our valued customers across the US
                and beyond. Our highly curated portfolio presented on the most
                relevant platform in the home plan industry is what sets us
                apart from the competition.
              </p>
              <p>
              We are interested in adding high quality home plans that reflect today's lifestyle. The designs must include strong visual presentation through photorealistic renderings and/or top-quality photographs. Please fill out the form below, upload samples of your work and tell us a little something about you!
              </p>
            </div>
          </div>
          <div className="col-lg-8">
            <h4>How to Join</h4>
            <p className="mb-0">
              To get the process started, we need to see samples of your work,
              either online or by email. Fill out the form below, and we’ll
              begin the review.
            </p>
            <div className="form">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="first name"
                />
                <label for="floatingInput">first name</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Last Name"
                />
                <label for="floatingInput">Last Name</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Email"
                />
                <label for="floatingInput">Email</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Website"
                />
                <label for="floatingInput">Website</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Address"
                />
                <label for="floatingInput">Address</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Address"
                />
                <label for="floatingInput">City</label>
              </div>
              <div className="row g-2">
                <div className="col-md">
                  <div className="form-floating">
                    <select
                      className="form-select"
                      id="floatingSelectGrid"
                      aria-label="Floating label select example"
                    >
                      <option selected>Open this select menu</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                    <label for="floatingSelectGrid">State or Province</label>
                  </div>
                </div>
                <div className="col-md">
                  <div className="form-floating mb-3 ms-2">
                    <input
                      type="email"
                      className="form-control"
                      id="floatingInputGrid"
                      placeholder="name@example.com"
                      value="mdo@example.com"
                    />
                    <label for="floatingInputGrid">Telephone</label>
                  </div>
                </div>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Address"
                />
                <label for="floatingInput">Postal Code</label>
              </div>
              <div className="form-floating mb-3">
                <select
                  className="form-select"
                  id="floatingSelectGrid"
                  aria-label="Floating label select example"
                >
                  <option selected>Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
                <label for="floatingSelectGrid">Country</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Address"
                />
                <label for="floatingInput">Company</label>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea2"
                
                ></textarea>
                <label for="floatingTextarea2">Tell Us Few More Things</label>
              </div>
              <p className="note">
                Please attach two sets of construction documents (preferably
                stamped "not for construction"), samples of renderings and
                marketing plans for our review. At least one attachment is
                required.
              </p>
              <div className="mb-3 upload">
                <label for="formFile" className="form-label ">Upload working Drawings</label>
                <input className="form-control" type="file" id="formFile" />
                </div>
               <div className="mb-3 ">
               <span>Two sets of construction documents (preferably stamped with "not for construction").</span>
               </div>
                <div className="mb-3 upload">
                <label for="formFile" className="form-label ">Upload Marketing Assets</label>
                <input className="form-control" type="file" id="formFile" />
                </div>
                <div className="mb-3">
                <span>Exterior renderings and/or photos of 4-6 separate designs . Include 2-3 interior elevations or photos for each design if available.</span>
                </div>
                <div className="mb-3 upload">
                <label for="formFile" className="form-label ">Upload Floor Plans</label>
                <input className="form-control" type="file" id="formFile" />
                </div>
                <div className="mb-3">
                <span>Marketing floor plans for at least 2 designs.</span>
                </div>
                
            </div>
            <button type="button" class="btn btn-secondary search mb-5 mt-2">Submit</button>
          </div>
        </div>
      </div>
    </section>
    </Layout>
  );
}

export default Designers;
