import { Navigate, Outlet } from "react-router-dom";
import { toast } from 'react-toastify';
const PrivateRoute = () => {
  const adminToken = window.sessionStorage.getItem("token");
  if (adminToken) {
    return <Outlet />;
  } else {
    // toast.error('Please Login to continue')
    // return <Navigate to={"/SignIn"} />;
    return <Outlet />;

  }
};
export default PrivateRoute;