import React from 'react'
import Layout from './Layout'
import { Link } from 'react-router-dom'

const ReturnPolicy = () => {
  return (
    <>
      <Layout>
        <div className='m-5 mobil'>
        <section className='changes_orders'>
        <div className='container'>
            <h3>Changes To Existing Orders</h3>
            <div className='contin'>
                <p>If you need to make a change to a plan order post-purchase such as upgrading to the CAD files, changing the foundation, or reversing the orientation, we're here to help.</p>
                <p>Most of our designers will allow upgrades up to one year from point of purchase. We can apply the previous sale to the upgrade order and collect the difference but in some cases, designers charge an administrative fee to reissue a plan in addition to the upgrade amount. Email us at 
                                    <a href="mailto: rholzsquad.studio@gmail.com" >rholzsquad.studio@gmail.com</a>
if you need assistance with an upgrade.</p>
            </div>
        </div>
    </section>
             </div>
      </Layout>
    </>
  )
}

export default ReturnPolicy
