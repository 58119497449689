import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Layout from './Layout';
import googleImg from "../assets/images/google.png";
import facebookImg from "../assets/images/Icon_11_.png";
import amazonImg from "../assets/images/image_149.png";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify'; 
import { loginAction } from '../redux/actions/AuthAction';
import { signInWithPopup } from 'firebase/auth';
import { auth, googleProvider,facebookProvider } from '../firebaseconfig'; // import Firebase auth and provider
import Api from '../service/Api';

const SignInEmail = () => {
    const dispatch = useDispatch(); 
    const navigate = useNavigate();

    const validationSchema = Yup.object({
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required')
    });

    // const handleGoogleSignIn = async () => {
    //     try {
    //       const result = await signInWithPopup(auth, googleProvider);
    //       console.log(result.user); // Google user info is in result.user
    //     } catch (error) {
    //       console.error("Error during sign-in: ", error);
    //     }
    //   };


    const handleGoogleSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;
            console.log('usedetails',user); // Google user info is in result.user
    
            // Extract relevant user information
            const token = await user.getIdToken(); // Firebase token
            const userData = {
                // email: user.email,
                // name: user.displayName,
                social_token: token,
                social_type: "GOOGLE"
                // Include any other fields needed by /social-login API
            };
    
            // Call /social-login API
            const response = await Api.post('/social-login', userData); // or fetch('/social-login', {...})
            
            if (response.status === 200) {
                toast.success("Logged in successfully!");
                console.log('sdfsdfsfsf',response.data)
                sessionStorage.setItem('token', response?.data?.data?.token);

                navigate('/home'); // Redirect to your desired page
            } else {
                toast.error("Social login failed.");
            }
        } catch (error) {
            console.error("Error during Google sign-in: ", error);
            toast.error("Error during Google sign-in.");
        }
    };

    //   const handleFacebookSignIn = async () => {
    //     try {
    //       const result = await signInWithPopup(auth, facebookProvider);
    //       console.log(result.user); // The signed-in user info
    //     } catch (error) {
    //       console.error("Error during sign-in: ", error);
    //     }
    //   };

    const handleFacebookSignIn = async () => {
        try {
            // Sign in with Facebook using Firebase
            const result = await signInWithPopup(auth, facebookProvider);
    
            // Get the signed-in user details
            const user = result.user;
            console.log('User details:', user);
    
            // Fetch the Firebase token for the user
            const token = await user.getIdToken();
    
            // Prepare data for your backend's social login endpoint
            const userData = {
                social_token: token, // Firebase ID token
                social_type: "FACEBOOK" // Specify Facebook as the login type
            };
    
            // Call your backend's /social-login endpoint
            const response = await Api.post('/social-login', userData);
    
            if (response.status === 200) {
                // Successfully logged in
                toast.success("Logged in successfully!");
                console.log('Backend response:', response.data);
    
                // Save the token to sessionStorage
                sessionStorage.setItem('token', response?.data?.data?.token);
    
                // Navigate to the desired page
                navigate('/home');
            } else {
                // Handle errors from the backend
                toast.error("Social login failed.");
            }
        } catch (error) {
            // Catch and display any errors during the process
            console.error("Error during Facebook sign-in:", error);
            toast.error("Error during Facebook sign-in.");
        }
    };
    
    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const data = await dispatch(loginAction(values));
            if(data?.payload?.status === 200){
                toast.success(`${data?.payload?.message} and your Otp is ${data?.payload?.otp}`)
                navigate(`/verifyOtp?otp=${data?.payload?.otp}`)
            } else {
                toast.error(data?.payload?.message)
            }
        } catch (error) {
            console.error("Submission error:", error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Layout>
            <section className="login-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="pt-content">
                                <ul>
                                    <li>Save searches and favorite plans.</li>
                                    <li>Access your account from any device including favorites, cart, and order history.</li>
                                    <li>Sign up for our newsletter for coupons and sales.</li>
                                    <li>No password required.</li>
                                </ul>
                                <div className="text-end pb-4">
                                    <img src="./images/house-bg.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="login-form">
                                <h2>Login or Register to Enjoy These Great Benefits</h2>
                                <Formik
                                    initialValues={{ email: '' }}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <div className="mb-3">
                                                <Field
                                                    type="email"
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Email Address"
                                                />
                                                <ErrorMessage
                                                    name="email"
                                                    component="div"
                                                    className="text-danger"
                                                />
                                            </div>
                                            <p>By registering you are accepting our <a href="#">terms and conditions</a> and <a href="#">privacy policy</a></p>
                                            <button
                                                type="submit"
                                                className="btn btn-primary btn-login"
                                                disabled={isSubmitting}
                                            >
                                                Continue With Email
                                            </button>
                                            <div className="signin">
                                                <hr /><span>Or Sign with</span><hr />
                                            </div>
                                            <div className="social-media-btns">
                                                <button  onClick={handleGoogleSignIn} type="button" className="btn btn-light">
                                                    <img src={googleImg} alt="" /> Continue with Google
                                                </button>
                                                <button onClick={handleFacebookSignIn}  type="button" className="btn btn-light">
                                                    <img src={facebookImg} alt="" /> Continue with Facebook
                                                </button>
                                                {/* <button type="button" className="btn btn-light">
                                                    <img src={amazonImg} alt="" /> Continue with Amazon
                                                </button> */}
                                            </div>
                                            <div className="lost-psd text-center">
                                                <Link to={`/SignIn`} >Login with Password</Link>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default SignInEmail;
