// planStylesSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Api from '../../service/Api';

// Thunk to fetch plan styles from API
export const getPlanStyles = createAsyncThunk(
  'planStyles/getPlanStyles',
  async (filters = {}, { rejectWithValue }) => {
    try {
      const response = await Api.get('/plan-styles', {
        params: filters, // Add filters if needed
      });
      return response.data; // Assuming the API returns the plan styles data in response.data
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Slice
const planStylesSlice = createSlice({
  name: 'planStyles',
  initialState: {
    styles: [], // Plan styles data
    loading: false, // Loading state for the API call
    error: null, // Error state for any issues
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPlanStyles.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear any previous errors
      })
      .addCase(getPlanStyles.fulfilled, (state, action) => {
        state.loading = false;
        state.styles = action.payload; // Store the fetched plan styles
      })
      .addCase(getPlanStyles.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store the error if request fails
      });
  },
});

export default planStylesSlice.reducer;
