import "./App.css";
import './assets/css/style.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PublicRoute from "./components/PublicRoute";
import PrivateRoute from "./components/PrivateRoute";
import ResetPassword from "./pages/ResetPassword";
import VerifyOtp from "./pages/VerifyOtp";
import SignIn from "./pages/SignIn";
import Home from "./pages/Home";
import SignInEmail from "./pages/SignInEmail";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SetPassword from "./pages/SetPassword";
import PropertyList from "./pages/PropertyList";
import Styles from "./pages/Styles";
import CostToBuild from "./pages/CostToBuild";
import MultiFamily from "./pages/MultiFamily";
import Services from "./pages/Services";
import Garages from "./pages/Garages";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import PropertyDetail from "./pages/PropertyDetail";
import "pure-react-carousel/dist/react-carousel.es.css";
import CopyRight from "./pages/CopyRight";
import Favorite from "./pages/Favorite";
import FavoriteDetails from "./pages/FavoriteDetails";
import FAQ from "./pages/FAQ";
import Usersettings from "./pages/Usersettings";
import Checkout from "./pages/Checkout";
import Testimonial from "./pages/Testimonial";
import Reviews from "./pages/Reviews";
import Pricing from "./pages/Pricing";
import Blog from "./pages/Blog";
import Cart from "./pages/Cart";
import BlogDetails from "./pages/BlogDetails";
import ReturnPolicy from "./pages/RetrunPolicy";
import Included_our_plans from "./pages/servicepages/included";
import Modification_services from "./pages/servicepages/modification";
import Reward_program from "./pages/servicepages/reward";
import Builder_membership from "./pages/servicepages/buildermember";
import Real_estate_reward_program from "./pages/servicepages/realestatereward";
import Designers from "./pages/servicepages/designer";
import Affiliate_program from "./pages/servicepages/becomaffilate";
import Copyryt from "./pages/servicepages/copyryt";
import Home_products from "./pages/servicepages/homeproduct";
import SignUp from "./pages/signup";
import SignUpEmail from "./pages/signupemail";
import TestCheckoutPage from "./pages/testcheckout";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/SignIn" element={<SignIn />}></Route>
            <Route path="/signup" element={<SignUp />}></Route>
            <Route path="/SignUpEmail" element={<SignUpEmail />}></Route>

            <Route path="/SignInEmail" element={<SignInEmail />}></Route>
            <Route path="/verifyOtp" element={<VerifyOtp />}></Route>
            <Route path="/resetPass" element={<ResetPassword />} />
            <Route path="/setPassword" element={<SetPassword />} />
            <Route path="signin" element={<SignIn />}></Route>
            <Route path="/" element={<Home />}></Route>








         
        <Route path="/whats-included" element={<Included_our_plans />} />
        <Route path="/modifications" element={<Modification_services />} />
        <Route path="/custom-house-plans" element={<Reward_program />} />
        <Route path="/for-builder" element={<Builder_membership />} />
        <Route path="/for-real-estate-agents" element={<Real_estate_reward_program />} />
        <Route path="/become-ad-designer" element={<Designers />} />
        <Route path="/become-affiliate" element={<Affiliate_program />} />
        <Route path="/photo-removal-request" element={<Copyryt />} />
        <Route path="/home-products-services" element={<Home_products />} />
        <Route path="/styles" element={<Home_products />} />


          </Route>

          <Route element={<PrivateRoute />}>
            {/* <Route index element={<DashboardLayout />} /> */}
            <Route path="/propertyList" element={<PropertyList  />}></Route>
            <Route path="/property/:id" element={<PropertyDetail />}></Route>
            <Route path="/style" element={<Styles />}></Route>
            <Route path="/cost-to-build" element={<CostToBuild />}></Route>
            {/* <Route path="/multi-family" element={<MultiFamily />}></Route> */}
            <Route path="/multi-family" element={<PropertyList defaultFilters={{ plan_type: [2] }} />}></Route>
            <Route path="/home" element={<Home />}></Route>

            <Route path="/services" element={<Services />}></Route>
            <Route path="/garages" element={<Garages />}></Route>
            <Route path="/about-us" element={<AboutUs />}></Route>
            <Route path="/contact-us" element={<ContactUs />}></Route>
            <Route path="/copyright" element={<CopyRight />}></Route>
            <Route path="/faq" element={<FAQ />} ></Route>
            <Route path="/usersettings" element={<Usersettings />} ></Route>
            <Route path="/favorite" element={<Favorite />} ></Route>
            <Route path="/favoritedetails" element={<FavoriteDetails />} ></Route>
            <Route path="/checkout" element={<Checkout />} ></Route>
            <Route path="/pricing" element={<Pricing />} ></Route>
            <Route path="/testimonial" element={<Testimonial />} ></Route>
            <Route path="/reviews" element={<Reviews />} ></Route>
            <Route path="/blog" element={<Blog />} ></Route>
            <Route path="/returnpolicy" element={<ReturnPolicy />} ></Route>

            <Route path="/blogdetails" element={<BlogDetails />} ></Route>

            <Route path="/cart" element={<Cart />} ></Route>

            <Route path="/testcheckout" element={<TestCheckoutPage/>} ></Route>

            <Route path="/home-search" element={<PropertyList defaultFilters={{}} />} />


<Route path="/home-filtered" element={<PropertyList defaultFilters={{plan_type:[1]}} />} />


<Route path="/garage-apartments" element={<PropertyList defaultFilters={{plan_type:[1]}} />} />
<Route path="/detached-garage-plans" element={<PropertyList defaultFilters={{plan_type:[1],}} />} />
<Route path="/rv-garage-plans" element={<PropertyList defaultFilters={{garage_type:[5]}} />} />
<Route path="/garage-plans-with-workshop" element={<PropertyList defaultFilters={{special_features:[6]}} />} />
<Route path="/carports" element={<PropertyList defaultFilters={{garage_type:[3]}} />} />
<Route path="/garage-plans-with-1-bedroom" element={<PropertyList defaultFilters={{plan_type:[1]}} />} />
<Route path="/garage-plans-with-2-bedrooms" element={<PropertyList defaultFilters={{plan_type:[1]}} />} />
<Route path="/1-car-garage-plans" element={<PropertyList defaultFilters={{plan_type:[2,4],no_of_vehicles:[1]}} />} />
<Route path="/2-car-garage-plans" element={<PropertyList defaultFilters={{plan_type:[2],no_of_vehicles:[2]}} />} />
<Route path="/3-car-garage-plans" element={<PropertyList defaultFilters={{plan_type:[2],no_of_vehicles:[3]}} />} />



          </Route>
        </Routes>
        <ToastContainer limit={1} />
      </BrowserRouter>
    </div>
  );
}

export default App;