import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useLocation, useNavigate } from 'react-router-dom';
import { orderStatus } from '../service/apiService';

// Load Stripe publishable key
const stripePromise = loadStripe('pk_test_51QD3gqC5m2qCeXrMOF59t9jMVidOGB4ovsL3uPBveHtA7ESaP9CEoNppOZIAv4Gx7PnTSw1iBDbxr49sPBOW4Kf700ERgvLKJz');

const CheckoutForm = () => {
    
    const location = useLocation();
    const navigate = useNavigate()
   
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    
    // Replace with your actual client secret for testing
    // const clientSecret = 'pi_3QRC15C5m2qCeXrM0swDDiVb_secret_kMl0O6eq67lRNFkQmCFCBNhZC';
    const clientSecret = location.state?.clientSecretId; // Retrieve clientSecret from navigation state
   console.log('clientSecretid is here ', clientSecret);
    if (!clientSecret) {
         <div>Error: Missing client secret. Please try again.</div>;
    }
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!stripe || !elements) {
            setError('Stripe has not been fully initialized.');
            setLoading(false);
            return;
        }

        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
            setError('CardElement not found.');
            setLoading(false);
            return;
        }

        try {
            const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement,
                },
            });

            if (error) {
                setError(error.message);
                setLoading(false);
                return;
            }

    //         setSuccess(true);
    //         alert(`Payment successful! Payment Intent ID: ${paymentIntent.id}`);
    //     } catch (err) {
    //         setError('An unexpected error occurred.');
    //         console.error(err);
    //     } finally {
    //         setLoading(false);
    //     }
    // };
    try {
        const response = await orderStatus({
            intent_id: paymentIntent.id,
        });

        if (response.status === 200) {
            setSuccess(true);
            navigate('/cart')
            console.log('Order status updated:', response.data);
        } else {
            setError('Failed to update order status.');
        }
    } catch (apiError) {
        console.error('Order status API error:', apiError);
        setError('An error occurred while updating the order status.');
    }
} catch (err) {
    setError('An unexpected error occurred.');
    console.error(err);
} finally {
    setLoading(false);
}
};

    return (
        <div className="checkout-form">
            <h1>Rholzsquad Payments</h1>

        <form onSubmit={handleSubmit}>
            <CardElement className="stripe-card-element" />
            <button type="submit" disabled={!stripe || loading || success}>
                {loading ? 'Processing...' : success ? 'Paid' : 'Pay Now'}
            </button>
            {error && <div className="error">{error}</div>}
                {success && <div className="success">Payment Successful!</div>}
        </form>
        </div>

    );
};

const TestCheckoutPage = () => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    );
};

export default TestCheckoutPage;
