// import React, { useEffect } from 'react'
// import Layout from './Layout'
// import imggal1 from "../assets/images/img-gal1.png";
// import imggal2 from "../assets/images/img-gal2.png";
// import imggal3 from "../assets/images/img-gal3.png";
// import imggal4 from "../assets/images/imggal4.png";
// import imggal5 from "../assets/images/imggal5.png";
// import imggal6 from "../assets/images/imggal6.png";
// import imggal7 from "../assets/images/imggal7.png";
// import { getPlanStyles } from '../redux/reducers/planStylesSlice';
// import { useDispatch } from 'react-redux';

// const Styles = () => {

//   const dispatch = useDispatch();

//   useEffect(() => {
//     // Fetch plan styles when component loads
//     dispatch(getPlanStyles());
//   }, [dispatch]);

//   return (
//     <>
//     <Layout>
//     <section className="gallery">
//     <div className="top-heading">
    
//       <h3>Housing Styles</h3>
//     </div>
//     <div className="row">
//       <div className="col-lg-4">
//         <div className="img-wrap">
//           <img src={imggal1} />
//           <div class="overlay"></div>
//           <div className="img-text">
//             <p>Italian Kitchen</p>
//           </div>
//         </div>
//       </div>
//       <div className="col-lg-4">
//         <div className="img-wrap">
//           <img src={imggal2} />
//           <div class="overlay"></div>
//           <div className="img-text">
//             <p>Italian Kitchen</p>
//           </div>
//         </div>
//       </div>
//       <div className="col-lg-4">
//         <div className="img-wrap">
//           <img src={imggal3} />
//           <div class="overlay"></div>
//           <div className="img-text">
//             <p>Italian Kitchen</p>
//           </div>
//         </div>
//       </div>
//     </div>
//     <div className="row">
//         <div className="col-lg-3">
//         <div className="img-wrap">
//           <img src={imggal4} />
//           <div class="overlay"></div>
//           <div className="img-text">
//             <p>Italian Kitchen</p>
//           </div>
//         </div>
//         </div>
//         <div className="col-lg-3">
//         <div className="img-wrap">
//           <img src={imggal5} />
//           <div class="overlay"></div>
//           <div className="img-text">
//             <p>Italian Kitchen</p>
//           </div>
//         </div>
//             </div>
//             <div className="col-lg-3">
//             <div className="img-wrap">
//           <img src={imggal6} />
//           <div class="overlay"></div>
//           <div className="img-text">
//             <p>Italian Kitchen</p>
//           </div>
//         </div>
//             </div>
//             <div className="col-lg-3">
//             <div className="img-wrap">
//           <img src={imggal7} />
//           <div class="overlay"></div>
//           <div className="img-text">
//             <p>Italian Kitchen</p>
//           </div>
//         </div>
//             </div>
//     </div>
//     <div className="row">
//         <div className="col-lg-3">
//         <div className="img-wrap">
//           <img src={imggal4} />
//           <div class="overlay"></div>
//           <div className="img-text">
//             <p>Italian Kitchen</p>
//           </div>
//         </div>
//         </div>
//         <div className="col-lg-3">
//         <div className="img-wrap">
//           <img src={imggal5} />
//           <div class="overlay"></div>
//           <div className="img-text">
//             <p>Italian Kitchen</p>
//           </div>
//         </div>
//             </div>
//             <div className="col-lg-3">
//             <div className="img-wrap">
//           <img src={imggal6} />
//           <div class="overlay"></div>
//           <div className="img-text">
//             <p>Italian Kitchen</p>
//           </div>
//         </div>
//             </div>
//             <div className="col-lg-3">
//             <div className="img-wrap">
//           <img src={imggal7} />
//           <div class="overlay"></div>
//           <div className="img-text">
//             <p>Italian Kitchen</p>
//           </div>
//         </div>
//             </div>
//     </div>
   
//   </section>
//     </Layout>
//     </>
//   )
// }

// export default Styles










import React, { useEffect, useState } from 'react';
import Layout from './Layout';
import imggal2 from "../assets/images/img-gal2.png";

// import { useDispatch } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { getPlanStyles } from '../redux/reducers/planStylesSlice';
import { useNavigate } from 'react-router-dom';

const Styles = () => {
  // const [styles, setStyles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
const navigate = useNavigate()
  const { styles, loading: stylesLoading, error: stylesError } = useSelector((state) => state.planStyles);
  const dispatch = useDispatch();
 const   initialFilters  ={
    keyword: '',
    min_sq_ft: '',
    max_sq_ft: '',
    bedrooms: '',
    bathrooms: '',
    min_footprint_width: '',
    max_footprint_width: '',
    min_footprint_depth: '',
    max_footprint_depth: '',
    min_footprint_height: '',
    max_footprint_height: '',
    plan_type: [],
    plan_style_id: '',
    floors: '',
    no_of_vehicles: [],
    garage_type: [],
    garage_location: [],
    bed_bath_options: [],
    kitchen_dinning: [],
    laundry_location: [],
    additional_rooms: [],
    outdoor_features: [],
    foundation: [],
    special_features: [],
    exterior_walls: [],
    collections: [],
    lot_features: [],
    sort_by: '',
    pagination: '',
    limit: ''
  };
  let debounceTimer;
  const [filters, setFilters] = useState(initialFilters);


  

  const handleSearchClick = (plandata) => {
    // Filter out empty values from filters
    const selectedFilters = Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => value !== '' && value !== null && value.length > 0)
    );

    // Add plan_style_id directly here when navigating
    const finalFilters = {
      ...selectedFilters,
      plan_style_id: plandata, // Pass the selected plan_style_id directly
    };

    console.log('--selected filter', finalFilters);

    // Navigate to PropertyList page and pass selectedFilters in state
    navigate('/home-search', {
      state: {
        selectedFilters: finalFilters, // Passing the updated filters including plan_style_id
      },
    });
  };  
   // Handle when a style is clicked
   const handleStyleClick = (styleId) => {
    // Update plan_style_id in filters state
    setFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
        plan_style_id: [styleId], // Set the selected style's id
      };
      handleSearchClick(); // Trigger the search click immediately after selecting a style
      return newFilters;
    });
  };
  useEffect(() => {
    // Fetch plan styles when component loads
    console.log(styles,'----------------->')
    dispatch(getPlanStyles());
  }, [dispatch]);

  

  return (
    <Layout>
      <section className="gallery">
        <div className="top-heading">
          <h3>Housing Styles</h3>
        </div>
        {/* {loading && <p>Loading...</p>} */}
        {error && <p>Error: {error}</p>}
        <div className="row">
        {stylesLoading ? (
          <h2 className='text-center'>Loading plan styles...</h2>
        ) : stylesError ? (
          <p>Error loading plan styles: {stylesError}</p>
        ) : (
          styles?.data?.length > 0 ? (
            styles?.data?.map((style) => (
              <div className="col-lg-4" key={style.id}>
                <div className="img-wrap" onClick={() => handleSearchClick(style._id)}>
                  <img src={style.image || imggal2} alt={style.name} />
                  <div className="overlay"></div>
                  <div className="img-text">
                    <p>{style.name}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No plan styles available.</p>
          )
        )}
      </div>
      </section>
    </Layout>
  );
};

export default Styles;


