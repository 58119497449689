// import React, { useEffect } from 'react';
// import Layout from './Layout';
// import imghouse1 from "../assets/images/img1.png";
// import youtubr from "../assets/images/youtubr.svg";
// import fav from "../assets/images/fav.svg";
// import exclusive from "../assets/images/exclusive.svg";
// import { useDispatch, useSelector } from 'react-redux';
// import { getFavourites } from '../redux/reducers/favourites';

// const FavoriteDetails = () => {
//   const dispatch = useDispatch();
//   const { favouritesList, loading, error } = useSelector((state) => state.favourites);
  
//   useEffect(() => {
//     dispatch(getFavourites());
//   }, [dispatch]);

//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   return (
//     <>
//       <Layout>
//         <section className='favourite '>
//           <div className='container'>
//             <div className='text-center top-tiles'>
//               <h2>My Favorites <span className='count'>({favouritesList?.count || 0})</span></h2>
//             </div>
//             <div className='row'>
//               {Array.isArray(favouritesList) && favouritesList.data?.map((favItem, index) => (
//                 <div key={index} className='col-lg-4 d-flex justify-content-center'>
//                   <div className="wrap">
//                     <div className="img-h">
//                       <img src={favItem?.property_id?.main_images[0] || imghouse1} alt="favorite item" />
//                       <div className="sociali-btn">
//                         <div className="youtube">
//                           <img src={youtubr} alt="YouTube" />
//                         </div>
//                         <div className="fav">
//                           <img src={fav} alt="Favorite" />
//                         </div>
//                       </div>
//                     </div>
//                     <div className="ex-tag">
//                       <img src={exclusive} alt="Exclusive" />
//                     </div>
//                     <div className="img-content">
//                       <div className="h-details">
//                         <div className="area">
//                           <strong>{favItem.area || '2369'}</strong>
//                           <p className="mb-0">Sq. Ft.</p>
//                         </div>
//                         <hr />
//                         <div className="area">
//                           <strong>{favItem.bedrooms || '3'}</strong>
//                           <p className="mb-0">Bed</p>
//                         </div>
//                         <hr />
//                         <div className="area">
//                           <strong>{favItem.bathrooms || '3'}</strong>
//                           <p className="mb-0">Bath</p>
//                         </div>
//                         <hr />
//                         <div className="area">
//                           <strong>{favItem.stories || '1'}</strong>
//                           <p className="mb-0">Story</p>
//                         </div>
//                         <hr />
//                         <div className="area">
//                           <strong>{favItem.cars || '2'}</strong>
//                           <p className="mb-0">Cars</p>
//                         </div>
//                       </div>
//                       <hr />
//                       <div className="d-flex justify-content-between">
//                         <div className="pln-no">
//                           <span>Plan Number</span>
//                           <h2>#{favItem.planNumber || '714035BTZ'}</h2>
//                         </div>
//                         <div className="pln-no">
//                           <span>Starts From</span>
//                           <h2 className="price">${favItem.price || '2,095'}</h2>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </section>
//       </Layout>
//     </>
//   );
// };

// export default FavoriteDetails;

import React, { useEffect } from 'react';
import Layout from './Layout';
import youtubr from "../assets/images/youtubr.svg";
import fav from "../assets/images/fav.svg";
import exclusive from "../assets/images/exclusive.svg";
import { useDispatch, useSelector } from 'react-redux';
import { getFavourites } from '../redux/reducers/favourites';
import imghouse1 from "../assets/images/img1.png";

const FavoriteDetails = () => {
  const dispatch = useDispatch();
  const { favouritesList, loading, error } = useSelector((state) => state.favourites);

  useEffect(() => {
    dispatch(getFavourites());
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Check if favouritesList has the data array
  const favoritesData = favouritesList?.data || [];

  return (
    <>
      <Layout>
        <section className='favourite '>
          <div className='container'>
            <div className='text-center top-tiles'>
              <h2>My Favorites <span className='count'>({favoritesData.length})</span></h2>
            </div>
            <div className='row'>
              {favoritesData.map((favItem, index) => {
                const property = favItem.property_id || {};
                return (
                  <div key={index} className='col-lg-4 d-flex justify-content-center'>
                    <div className="wrap">
                      <div className="img-h pt-0">
                        {/* <img  src={ favItem?.property_id?.main_images?.[0] || imghouse1} alt="favorite item" /> */}
                        <img 
  src={favItem?.property_id?.main_images?.[0] || imghouse1} 
  alt="favorite item" 
  style={{ width: '100%', height: '250px' }} 
/>
                        <div className="sociali-btn">
                          <div className="youtube">
                            <img src={youtubr} alt="YouTube" />
                          </div>
                          <div className="fav">
                            <img src={fav} alt="Favorite" />
                          </div>
                        </div>
                      </div>
                      <div className="ex-tag">
                        <img src={exclusive} alt="Exclusive" />
                      </div>
                      <div className="img-content">
                        <div className="h-details">
                          <div className="area">
                            <strong>{property.sq_ft || 'N/A'}</strong>
                            <p className="mb-0">Sq. Ft.</p>
                          </div>
                          <hr />
                          <div className="area">
                            <strong>{property.beds || 'N/A'}</strong>
                            <p className="mb-0">Bed</p>
                          </div>
                          <hr />
                          <div className="area">
                            <strong>{property.baths || 'N/A'}</strong>
                            <p className="mb-0">Bath</p>
                          </div>
                          <hr />
                          <div className="area">
                            <strong>{property.story || 'N/A'}</strong>
                            <p className="mb-0">Story</p>
                          </div>
                          <hr />
                          <div className="area">
                            <strong>{property.cars || 'N/A'}</strong>
                            <p className="mb-0">Cars</p>
                          </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                          <div className="pln-no">
                            <span>Plan Number</span>
                            <h2>#{property.plan_number || 'N/A'}</h2>
                          </div>
                          <div className="pln-no">
                            <span>Starts From</span>
                            <h2 className="price">${property.price || 'N/A'}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default FavoriteDetails;
