import React from 'react'
// import './style.css'
import membership from '../../assets/images/membership.png'
import { Link } from 'react-router-dom'
import Layout from '../Layout'

function Builder_membership() {
  return (
    <Layout>
   <section className='membership'>
       <div className='container'>
      <div className='text-center'>
      <h2>Builder Membership & 
        Referral Program </h2>
        <img src={membership} />
      </div>
        <div className='other-contents'>
            <h4>Membership Benefits:</h4>
            <ul>
                <li>Exclusive access to AD’s Builder Trends Report released in January, May and September. These valuable reports will include our Top 5 Purchased Plans, specific state statistics, and other important data points. Having an inside track on buying behavior will help inform your purchasing decisions and discussions with prospective clients.</li>
                <li>Inclusion in our Builder Referral Program. Finding a reputable builder is often a daunting task for many customers and we often receive requests from clients to refer them to builders in their area. As part of the Builder Membership Program we will include you in our list of recommended builders to clients, providing a valuable funnel for potential new business.</li>
                <li>Prioritized promotion on AD’s social and email channels. AD is the undeniable frontrunner on all social media platforms for house plan marketers. We have active, lively engagement from our ever-growing community of followers. There is no better way to increase your visibility and drive new business than by featuring your homes on our social media channels.</li>
                <li>Cost-to-Build Reports Access: As a program member, you are entitled to 25 Cost-to-Build Reports annually at no cost to you. Our building cost estimate reports retail for $44.95 each and are extremely popular with consumers trying to gain an understanding of building cost in their area.</li>
            </ul>
        </div>
        <div className='other-contents'>
            <h4>Membership Fee:</h4>
            <p className='mb-0'>Architectural Designs Builder Membership & Referral Program: $195 annually (tax included)</p>
        </div>
        <div className='other-contents'>
            <h4>How to Subscribe:</h4>
            <ul>
                <li>
                    1. <span>Sign up</span> with Architectural Designs and select "I'm a professional home builder."
                </li>
                <li>2. Return to <Link to="">https://www.architecturaldesigns.com/subscription_packages</Link> and click SUBSCRIBE NOW to enroll.</li>
            </ul>
        </div>
        <div className='other-contents'>
            <p className='mb-0'>If you have any issues with enrolling, please email us at <span> builders@architecturaldesigns.com.</span></p>
        </div>
       </div>
   </section>
   </Layout>
  )
}

export default Builder_membership