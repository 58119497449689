import React from 'react'
// import './style.css'
import affiliate from '../../assets/images/affiliate.png'
import { Link } from 'react-router-dom'
import Layout from '../Layout'

function Affiliate_program() {
  return (
    <Layout>
   <section className='affiliate-program'>
       <div className='container'>
       <div className='text-center'>
      <h2>Become An Architectural Designs Affiliate </h2>
        <img src={affiliate} />
      </div>
      <div className='other-contents'>
          <p>Do you have a website or social media following where you would like to promote Architectural Designs’ home plans and receive a commission on any sales you drive? We would love to partner with you!</p>
          <p>
We offer a 5% commission on any plan sale made through our affiliate program, supported through the <span>Commission Junction</span> platform.
The steps to become an Architectural Designs partner are outlined below!</p>
      </div>
      <div className='other-contents'>
          <h4>Step 1: Create a CJ.com affiliate account</h4>
          <ul>
              <li>Use the following link to sign up for a CJ.com affiliate link, and apply for the Architectural Designs program. <span><Link to="">Click Here</Link></span></li>
              <li>If you already have a CJ affiliate account, you can request to join our program terms here: Click Here <span><Link to="">Click Here</Link></span></li>
          </ul>
          <h4>Step 2: Approval Process by Architectural Designs for our 5% commission program</h4>
          <ul>
              <li>When you apply to our CJ.com program, we will review your application and notify you within 5-7 days if you’ve been approved</li>
          </ul>
          <h4>Step 3: Feature AD plans on your website or social media and begin to earn commissions!</h4>
          <ul>
              <li>CJ.com creates a special coded URL for the specific plan you’d like to feature. You can also link directly to our main URL, and collections, using your CJ tracking code.</li>
              <li>You’ll want to access Product Links under the AD catalogue within CJ.com.</li>
          </ul>
      </div>
      <div className='other-contents'>
      <h4>Are you a Builder?</h4>
      <p>If you are a builder and would like to feature our plans, please join our <span><Link to="">Builder Rewards</Link></span> program to learn more about the various ways builders can feature our home plans to their customers.</p>
          </div>
          <div className='other-contents'>
          <h4>Support and Questions:</h4>
      <p>For any assistance with setting up your account, please contact <span><Link to="">affiliates@architecturaldesigns.com</Link></span></p>
      <p>Thank you so much for your support of Architectural Designs and we look forward to partnering with you in the future!</p>
          </div>
       </div>
   </section>
   </Layout>
  )
}

export default Affiliate_program