import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../service/Api";

const loginAction = createAsyncThunk(
    'login', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/login', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })
    const registerAction = createAsyncThunk(
        'register', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.post('/signup', reqBody)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
    
        })

const verifyOtpAction = createAsyncThunk(
    'verify', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/reset-otp-verify', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const ForgotPasswordAction = createAsyncThunk(
    'forgot-password', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/forgot-password', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const forgotverifyEmailAction = createAsyncThunk(
    'forgot-verifyEmail', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/auth/forgot-verifyEmail', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })


const resetpasswordAction = createAsyncThunk(
    'reset-password', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/reset-password', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const adminResetPasswordAction = createAsyncThunk(
    'reset-password', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.put('/sub/new-password', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const adminSubResetPasswordAction = createAsyncThunk(
    'reset-password', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/sub/reset-password', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })


const setPasswordAction = createAsyncThunk(
    'set-password', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/set-password', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })


export {
    loginAction,
    verifyOtpAction,
    ForgotPasswordAction,
    forgotverifyEmailAction,
    resetpasswordAction,
    adminResetPasswordAction,
    adminSubResetPasswordAction,
    setPasswordAction,
    registerAction
};