// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider,FacebookAuthProvider  } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDFCMsE2317eB2wxx8qw7Kjn9qN9PlX5bQ",
  authDomain: "rohlzsquad.firebaseapp.com",
  projectId: "rohlzsquad",
  storageBucket: "rohlzsquad.appspot.com",
  messagingSenderId: "398351471696",
  appId: "1:398351471696:web:fc95d2c7abb9c66548ad55"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();
