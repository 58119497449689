import React from "react";
import { Link } from "react-router-dom";
// import './style.css'
import Layout from "../Layout";

function Copyryt() {
  return (
    <Layout>
    <section className="copyryt">
      <div className="container">
        <h3>Report Alleged Copyright Infringement</h3>
        <div className="row">
          <div className="col-lg-5">
            <div className="other-contents">
              <p>
                It is our policy to respond to notices of alleged infringement
                that comply with the Digital Millennium Copyright Act (the text
                of which can be found at the U.S. Copyright Office Web Site,
                <Link to=""> http://www.copyright.gov </Link>) and other
                applicable intellectual property laws. Our response may include
                removing or disabling access to material claimed to be the
                subject of infringing activity and/or terminating subscribers.
                If we remove or disable access in response to such a notice, we
                may notify the owner or administrator of the affected site or
                content so that he or she can make a counter notification. It is
                also our policy to document all notices of alleged infringement
                on which we act, including by sending a copy of the notice to
                one or more third parties or making it available to the public.
                You can see an example of such a publication at 
                <Link to="">http://lumendatabase.org/notices/861</Link>
              </p>
            </div>
            <div className="other-contents">
              <h4>Infringement Notification</h4>
              <p>
                To file a notice of infringement with us, you may use the form
                provided below.
              </p>
              <p>
                IMPORTANT: Misrepresentations made in your notice regarding
                whether material or activity is infringing may expose you to
                liability for damages (including costs and attorneys'
                fees). Courts have found that you must consider copyright
                defenses, limitations or exceptions before sending a notice.
                In one case involving online content, a company paid more than
                $100,000 in costs and attorneys fees after targeting content
                protected by the U.S. fair use doctrine. Accordingly, if you are
                not sure whether material available online infringes your
                copyright, we suggest that you first contact an attorney.
              </p>
            </div>
          </div>
          <div className="col-lg-7">
            <h4>Contact Information</h4>
            <p className="">
              To get the process started, we need to see samples of your work,
              either online or by email. Fill out the form below, and we’ll
              begin the review.
            </p>
            <div className="form">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="first name"
                />
                <label for="floatingInput">first name</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Last Name"
                />
                <label for="floatingInput">Last Name</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Email"
                />
                <label for="floatingInput">Company Name</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Email"
                />
                <label for="floatingInput">
                  Copyright Hodler You Represent(ex Self)
                </label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Email"
                />
                <label for="floatingInput">Email Address</label>
              </div>
              <div className="form-floating mb-3">
                <div className="form-floating">
                  <select
                    className="form-select"
                    id="floatingSelectGrid"
                    aria-label="Floating label select example"
                  >
                    <option selected>Open this select menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                  <label for="floatingSelectGrid">State or Province</label>
                </div>
              </div>
            </div>
            <h4>Your Copyright Work</h4>
            <p className="">
              In order to permit us to verify that your work actually appears at
              the URLs targeted by your notice, please provide us with
              information that identifies in sufficient detail the copyrighted
              work that you believe has been infringed upon.
            </p>
            <div className="form">
              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea2"
                ></textarea>
                <label for="floatingTextarea2">
                  Identify and describe the copyrighted work:{" "}
                </label>
              </div>
              <div className="mb-3">
                <span>
                  (For example, "The photographs of the black widow spider with
                  red spots and that of the black labrador retriever which can
                  be viewed at the URL below" or "My published book, "Touch Not
                  This Cat", is infringed by the text excerpted on the site,
                  beginning with the text 'I came home to find my cat sitting on
                  the kitchen counter.'")
                </span>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea2"
                ></textarea>
                <label for="floatingTextarea2">
                  Where can we see an authorized example of the work?{" "}
                </label>
              </div>
              <div className="mb-3">
                <span>
                  (Please provide URL(s) where an example of the copyrighted
                  work can be viewed. This will be used by our team to verify
                  that the work appears on the pages you are asking us to
                  remove.)
                </span>
              </div>
            </div>
            <h4>Sworn Statements</h4>
            <div className="form">
              <div class="mb-3 form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                />
                <label class="form-check-label" for="exampleCheck1">
                  I have a good faith belief that use of the copyrighted
                  materials described above as allegedly infringing is not
                  authorized by the copyright owner, its agent, or the law.
                </label>
              </div>
              <div class="mb-3 form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                />
                <label class="form-check-label" for="exampleCheck1">
                  The information in this notification is accurate and I swear,
                  under penalty of perjury, that I am the copyright owner or am
                  authorized to act on behalf of the owner of an exclusive right
                  that is allegedly infringed.
                </label>
              </div>
              <div class="mb-3 form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                />
                <label class="form-check-label" for="exampleCheck1">
                  I understand that a copy of each legal notice may be sent to
                  the Lumen project ( http://lumendatabase.org ) for publication
                  and annotation. I also understand that Lumen redacts personal
                  contact information from notices before publication, but in
                  many cases, will not redact my name.
                </label>
              </div>
            </div>
            <h4>Signature:</h4>
            <div className="form">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Last Name"
                />
                <label for="floatingInput">Signed on this date of</label>
              </div>
              <div className="mb-2">
                <span>MM/DD/YYYY (e.g. "12/19/2010")</span>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Last Name"
                />
                <label for="floatingInput">Signature</label>
              </div>
              <div className="mb-2">
                <span>
                  e.g. John A. Smith By typing your full name above, you are
                  providing us with your digital signature, which is as legally
                  binding as your physical signature. Please note that your
                  signature must exactly match the first and last names that you
                  entered at the top of this web form in order for your
                  submission to be successful.
                </span>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea2"
                ></textarea>
                <label for="floatingTextarea2">
                Where can we see an authorized example of the work?
                </label>
              </div>
            </div>
            <button type="button" class="btn btn-secondary search mb-5 mt-2">Submit</button>
          </div>
         
        </div>
      </div>
    </section>
    </Layout>
  );
}

export default Copyryt;
