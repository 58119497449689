import React from 'react'
// import './style.css'
import silver from '../../assets/images/silver.png'
import gold from '../../assets/images/gold.png'
import platinum from '../../assets/images/platinum.png'
import Layout from '../Layout'

function Real_estate_reward_program() {
  return (
    <Layout>
    <section className='rewrad-program'>
    <div className='container'>
        <h2>Real Estate Agent Rewards Program From Architectural Designs</h2>
        <div className='outer-cover'>
            <p>Welcome to the Architectural Designs Builder Rewards Program, where we reward builders with tiered discounts based on their lifetime purchases of home plans. Builders are the heart of our business and we could not be more grateful for the relationships we develop with them. Through our Rewards Program you have a dedicated customer service team at AD who can help with questions, assist with plan purchases and coordinate modification requests. You can reach us at <span> realestateagents@architecturaldesigns.com </span> or call our office directly at <span>203-761-8500</span> for assistance.
Your discount is a lifetime discount and does not reset with each calendar year.</p>
        </div>
        <div className='row'>
            <div className='col-lg-4'>
                <div className='wrapping-cover'>
                    <img src={silver} />
                    <h4>Silver Level</h4>
                    <p>Provide us with your company name and contact info and we will enroll you in our Builder Rewards Program so you can immediately take advantage of the 10% discount we extend on every home plan purchase. As you purchase additional plans with us, your discount increases as you move through the program!</p>
                </div>
            </div>
            <div className='col-lg-4'>
            <div className='wrapping-cover'>
                    <img src={gold} />
                    <h4>Gold Level</h4>
                    <p>With your 6th home plan purchase, your discount increases to 15% discount on all future home plan purchases from Architectural Designs.</p>
                </div>
                </div>
                <div className='col-lg-4'>
                <div className='wrapping-cover'>
                    <img src={platinum} />
                    <h4>Platinum Level</h4>
                    <p>With your 11th home plan purchase, your discount increases to a lifetime discount of 20% discount on all home plan purchases from Architectural Designs.</p>
                </div>
                </div>
        </div>
        <div className='how_join'>
            <h4>How You Join the Program:</h4>
            <ul>
                <li>1. When you order your first home plan, let us know you are a builder wanting to enroll in our Builder Rewards Program.</li>
                <li>2. If you haven't ordered yet but would like to enroll for future plan purchases, call us at<span>203-761-8500</span>  and request to join.</li>
                <li>3. Or, email us at<span>realestateagents@architecturaldesigns.com</span> </li>
            </ul>
        </div>
        <div className='how_join'>
            <h4>How You Can Feature Our Plans on Your Website:</h4>
            <p>Login with your builder email and then go to the instructions on our <span>Promoting AD Plans to Your Customers page.</span></p>
        </div>
    </div>
</section>
</Layout>
  )
}

export default Real_estate_reward_program