import { configureStore } from '@reduxjs/toolkit';
import authSlice from './reducers/authSlice';
import propertySlice from './reducers/propertySLice'; 
import contactUsReducer from './reducers/contactUsSlice';
import planStylesReducer from './reducers/planStylesSlice'
import  favouritesReducer from './reducers/favourites' ;  // Add your reducer here

const store = configureStore({
    reducer: {
        authData: authSlice,
        property: propertySlice,
        contactUs: contactUsReducer,
        planStyles: planStylesReducer,
        favourites: favouritesReducer,  // Add your reducer here
        // favourites: favouritesReducer,

    }
});

export default store;